import { Fragment, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import axios from "../../axios-orders";
import { Loader, SelectCard } from "../../components";
import { registerValidationSchema, taxIdFormatter } from "../../utils";
import Icon from "../../assets/images/icon-spectum.png";
import Logo from "../../assets/images/logo-simbuy.png";
import i18next from "../../i18n";

import Complete from "./Complete/Complete";
import Success from "./Success/Success";
import Error from "./Error/Error";
import { usePublicCountries } from "../../hooks/useCountries";
import { UserCompanyType } from "../../types";

const boxes = [
  { id: 1, label: i18next.t("register.form.buyer"), type: "BUYER" },
  { id: 2, label: i18next.t("register.form.seller"), type: "SELLER" },
];

type RegisterFormValues = {
  companyName: string;
  fullName: string;
  email: string;
  confirmEmail?: string;
  phone: string;
  type?: string;
  taxId: string;
  country: string;
  age?: boolean;
  password: string;
  confirmPassword?: string;
  termsAndConditions?: boolean;
  informationAgreement?: boolean;
};

export default function Register() {
  const { t } = useTranslation();

  const [userType, setUserType] = useState("SELLER");
  const [isLoading, setIsLoading] = useState(false);
  const [age, setAge] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [informationAgreement, setInformationAgreement] = useState(true);
  const [hidden, setHidden] = useState("");
  const [signUpResponse, setSignUpResponse] = useState({
    status: 0,
    data: {
      fullName: "",
    },
  });

  const isUrlParams = new URLSearchParams(useLocation().search).get("userType");
  const invitation = new URLSearchParams(useLocation().search).get(
    "invitationId"
  );

  const { data: countries } = usePublicCountries();

  const formOptions = { resolver: yupResolver(registerValidationSchema) };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<RegisterFormValues>(formOptions);

  const onSubmit = (data: RegisterFormValues) => {
    if (hidden) return;
    setIsLoading(true);
    const url = invitation
      ? `/auth/signup?invitationId=${invitation}`
      : "/auth/signup";
    axios
      .post(url, {
        ...data,
        taxId: taxIdFormatter(data.taxId),
        type: userType,
        userCompanyType:
          userType === UserCompanyType.BUYER ? UserCompanyType.BUYER : null,
      })
      .then((res) => {
        setSignUpResponse(res);
        setUserType("");
        reset();
      })
      .catch((err) => setSignUpResponse(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (isUrlParams && signUpResponse.status !== 201) setUserType(isUrlParams);
  }, []);

  return (
    <div className="grid grid-cols-2 gap-4 justify-evenly">
      <div className="col-span-2 md:col-span-1 my-2 min-h-screen px-4 md:px-8">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <img src={Logo} className="h-40 mx-auto" />
            {signUpResponse.status === 0 && !isLoading ? <Complete /> : null}
            {!(signUpResponse.status === 201) ? (
              <div className="flex gap-6 pt-6">
                {boxes.map((box) => (
                  <SelectCard
                    selected={userType === box.type}
                    key={box.id}
                    label={box.label}
                    onClick={() => setUserType(box.type)}
                  />
                ))}
              </div>
            ) : null}
            {signUpResponse.status !== 0 ? (
              signUpResponse.status === 201 ? (
                <Success signUpResponse={signUpResponse} />
              ) : (
                <Error />
              )
            ) : null}
            <Fragment>
              {userType === "BUYER" ||
              userType === "SELLER" ||
              !(signUpResponse.status === 201) ? (
                <form
                  className="bg-white rounded mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2 p-4 border shadow-md"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="col-span-2">
                    <p className="text-spectum text-xs font-bold">
                      {t("register.form.companyData")}
                    </p>
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <input
                      {...register("companyName", { required: true })}
                      className="w-full rounded shadow border-gray-400 p-1 text-xs"
                      id="companyName"
                      name="companyName"
                      type="text"
                      placeholder={t("register.form.companyName")}
                    />
                    {errors?.companyName && (
                      <span className="text-xs text-red-500">
                        {errors?.companyName?.message}
                      </span>
                    )}
                  </div>

                  <div className="col-span-2 md:col-span-1">
                    <input
                      {...register("taxId", { required: true })}
                      className="w-full rounded shadow border-gray-400 p-1 text-xs"
                      id="taxId"
                      name="taxId"
                      type="text"
                      placeholder={t("register.form.tax")}
                    />
                    {errors?.taxId && (
                      <span className="text-xs text-red-500">
                        {errors?.taxId?.message}
                      </span>
                    )}
                  </div>

                  <div className="col-span-2">
                    <select
                      {...register("country", { required: true })}
                      className="w-full rounded shadow border-gray-400 p-1 text-xs"
                      id="country"
                      name="country"
                    >
                      <option value="">{t("validations.country")}</option>
                      {countries?.map((country) => (
                        <option key={country.id} value={country.id}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                    {errors?.country && (
                      <span className="text-xs text-red-500">
                        {errors?.country?.message}
                      </span>
                    )}
                  </div>

                  <div className="col-span-2">
                    <p className="text-spectum text-xs font-bold">
                      {t("register.form.userData")}
                    </p>
                  </div>

                  <div className="col-span-2">
                    <input
                      {...register("fullName", { required: true })}
                      className="w-full rounded shadow border-gray-400 p-1 text-xs"
                      id="fullName"
                      name="fullName"
                      type="text"
                      placeholder={t("register.form.fullName")}
                    />
                    {errors?.fullName && (
                      <span className="text-xs text-red-500">
                        {errors?.fullName?.message}
                      </span>
                    )}
                  </div>

                  <div className="col-span-2 md:col-span-1">
                    <input
                      {...register("email", { required: true })}
                      className="w-full rounded shadow border-gray-400 p-1 text-xs"
                      id="email"
                      name="email"
                      type="email"
                      placeholder={t("register.form.email")}
                    />
                    {errors?.email && (
                      <span className="text-xs text-red-500">
                        {errors?.email?.message}
                      </span>
                    )}
                  </div>

                  <div className="col-span-2 md:col-span-1">
                    <input
                      {...register("confirmEmail", { required: true })}
                      className="w-full rounded shadow border-gray-400 p-1 text-xs"
                      id="confirmEmail"
                      name="confirmEmail"
                      type="email"
                      placeholder={t("register.form.confirmEmail")}
                    />
                    {errors?.confirmEmail && (
                      <span className="text-xs text-red-500">
                        {errors?.confirmEmail?.message}
                      </span>
                    )}
                  </div>

                  <div className="col-span-2">
                    <input
                      {...register("phone", { required: true })}
                      className="w-full rounded shadow border-gray-400 p-1 text-xs"
                      id="phone"
                      name="phone"
                      type="number"
                      placeholder={t("register.form.phone")}
                    />
                    {errors?.phone && (
                      <span className="text-xs text-red-500">
                        {errors?.phone?.message}
                      </span>
                    )}
                  </div>

                  <div className="col-span-2 hidden">
                    <label
                      htmlFor="type"
                      className="text-gray-700 mb-1 block text-xs font-normal uppercase"
                    >
                      {t("register.form.userType")}
                    </label>
                    <input
                      {...register("type", { required: true })}
                      className="w-full rounded shadow border-gray-400 p-1 text-xs"
                      id="type"
                      name="type"
                      type="text"
                      value={
                        userType === "BUYER"
                          ? t("userTypes.buyer")
                          : t("userTypes.seller")
                      }
                      disabled
                    />
                  </div>

                  <div className="col-span-2">
                    <input
                      {...register("password", { required: true })}
                      className="w-full rounded shadow border-gray-400 p-1 text-xs"
                      id="password"
                      name="password"
                      type="password"
                      placeholder={t("register.form.password")}
                    />
                    {errors?.password && (
                      <span className="text-xs text-red-500">
                        {errors?.password?.message}
                      </span>
                    )}
                  </div>

                  <div className="col-span-2">
                    <input
                      {...register("confirmPassword", { required: true })}
                      className="w-full rounded shadow border-gray-400 p-1 text-xs"
                      id="confirmPassword"
                      name="confirmPassword"
                      type="password"
                      placeholder={t("register.form.confirmPassword")}
                    />
                    {errors?.confirmPassword && (
                      <span className="text-xs text-red-500">
                        {errors?.confirmPassword?.message}
                      </span>
                    )}
                  </div>

                  <div className="border rounded shadow p-2">
                    <p className="text-xs text-gray-600">
                      {t("changePassword.title")}
                    </p>
                    <ul className="text-xs text-gray-600 list-disc">
                      <li className="ml-4">{t("changePassword.characters")}</li>
                      <li className="ml-4">{t("changePassword.uppercase")}</li>
                      <li className="ml-4">{t("changePassword.lowercase")}</li>
                      <li className="ml-4">{t("changePassword.number")}</li>
                      <li className="ml-4">{t("changePassword.special")}</li>
                    </ul>
                  </div>

                  <div className="col-span-2">
                    <legend className="sr-only">Notifications</legend>
                    <div className="relative mt-3 flex items-start">
                      <div className="flex h-5 items-center">
                        <input
                          {...register("age", { required: true })}
                          id="age"
                          aria-describedby="age-description"
                          name="age"
                          type="checkbox"
                          checked={age}
                          className="border-gray-300 h-4 w-4 rounded text-indigo-600 focus:ring-indigo-500 text-xs"
                          onChange={() => setAge((prevState) => !prevState)}
                        />
                      </div>
                      <div className="ml-3 text-xs">
                        <label
                          htmlFor="age"
                          className="text-gray-700 font-medium"
                        >
                          {t("register.form.age")}
                        </label>
                      </div>
                    </div>

                    {errors?.age && !age && (
                      <span className="mb-3 text-xs text-red-500">
                        {errors?.age?.message}
                      </span>
                    )}
                    <hr className="mb-3 mt-2" />

                    <div className="relative flex items-start">
                      <div className="flex h-5 items-center">
                        <input
                          {...register("termsAndConditions", {
                            required: true,
                          })}
                          id="termsAndConditions"
                          aria-describedby="termsAndConditions-description"
                          name="termsAndConditions"
                          type="checkbox"
                          checked={termsAndConditions}
                          className="border-gray-300 h-4 w-4 rounded text-indigo-600 focus:ring-indigo-500 text-xs"
                          onChange={() =>
                            setTermsAndConditions((prevState) => !prevState)
                          }
                        />
                      </div>
                      <div className="ml-3 text-xs">
                        <label
                          htmlFor="termsAndConditions"
                          className="text-gray-700 font-medium"
                        >
                          {t("register.form.accept")}{" "}
                          <a
                            href={
                              userType === "BUYER"
                                ? "https://spectum-docs.s3.sa-east-1.amazonaws.com/TC++Compradores+11.8.pdf"
                                : "https://spectum-docs.s3.sa-east-1.amazonaws.com/TC+Vendedores+11.8.pdf"
                            }
                            target="_blank"
                            rel="noreferrer"
                            className="text-spectum-light"
                          >
                            {t("register.form.terms")}
                          </a>
                          .
                        </label>
                      </div>
                    </div>

                    {errors?.termsAndConditions && !termsAndConditions && (
                      <span className="mb-3 text-xs text-red-500">
                        {errors?.termsAndConditions?.message}
                      </span>
                    )}
                    <hr className="mb-3 mt-2" />

                    <div className="relative flex items-start">
                      <div className="flex h-5 items-center">
                        <input
                          {...register("informationAgreement")}
                          checked={informationAgreement}
                          id="informationAgreement"
                          aria-describedby="informationAgreement-description"
                          name="informationAgreement"
                          type="checkbox"
                          className="border-gray-300 h-4 w-4 rounded text-indigo-600 focus:ring-indigo-500"
                          onChange={() =>
                            setInformationAgreement((prevState) => !prevState)
                          }
                        />
                      </div>
                      <div className="ml-3 text-xs">
                        <label
                          htmlFor="informationAgreement"
                          className="text-gray-700 font-medium"
                        >
                          {t("register.form.info")}
                        </label>
                      </div>
                    </div>
                    <p className="text-xs mt-4">
                      {t("register.form.please")}{" "}
                      <a
                        className="text-spectum-light"
                        href="https://spectum-docs.s3.sa-east-1.amazonaws.com/Politica+de+Privacidad+Spectum+11.8.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t("register.form.privacy")}
                      </a>{" "}
                      {t("register.form.personal")}
                    </p>
                  </div>
                  <div className="col-span-2">
                    <button
                      type="submit"
                      className="border-transparent flex w-full justify-center rounded-md border py-2 px-4 text-xs font-medium uppercase shadow-sm bg-spectum text-white hover:bg-spectum-light"
                    >
                      <span>
                        {t("register.form.cta")}{" "}
                        <span aria-hidden="true">&rarr;</span>
                      </span>
                    </button>
                  </div>
                  <div className="col-span-2 bg-gray-300 rounded py-8 px-4 sm:px-10 mt-10">
                    <p className="text-black text-xs">
                      {t("register.form.disclosure")}
                    </p>
                  </div>
                  <input
                    className="hidden"
                    value={hidden}
                    onChange={(e) => setHidden(e.target.value)}
                  />
                </form>
              ) : null}
            </Fragment>
          </>
        )}
      </div>
      <div className="hidden md:col-span-1 max-h-screen md:block relative w-1/2">
        <div
          className="h-full bg-spectum-ultraDark fixed top-0 right-0"
          style={{ width: "inherit" }}
        >
          <div className="flex h-full justify-center">
            <img className="mx-auto w-full" src={Icon} alt="Spectum" />
          </div>
        </div>
      </div>
    </div>
  );
}
