import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import i18next from "../../i18n";
import { OrderResponse, FileResponse, FileType } from "../../types";

const OrderFileList = ({ order }: { order: OrderResponse }) => {
  return (
    <div className="bg-white overflow-hidden text-xs">
      {order.files.map((file: FileResponse) => (
        <div key={file.fileURL} className="py-2 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-1 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt className="uppercase text-xxs font-medium text-gray-500">
                {file.type === FileType.ATTACHMENT &&
                  i18next.t("tenders.form.additionalDocument")}
                {file.type === FileType.SHEET &&
                  i18next.t("tenders.form.plication")}
                {file.type === FileType.CERTIFICATION &&
                  i18next.t("tenders.form.certificate")}
                {file.type === FileType.BRIEF &&
                  i18next.t("tenders.form.brief")}
                {file.type === FileType.SERVICE_PLACE &&
                  i18next.t("tenders.form.servicePlace")}
              </dt>
              <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-1">
                <a
                  href={file.fileURL}
                  target="_blank"
                  rel="noreferrer"
                  className="w-200 inline-flex items-center px-2 py-1 border border-transparent shadow-sm text-xxs leading-4 font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum"
                >
                  <ArrowDownTrayIcon
                    className="-ml-0.5 mr-2 h-4 w-4"
                    aria-hidden="true"
                  />
                  {file.type === FileType.ATTACHMENT &&
                    i18next.t("tenders.form.additionalDocument")}
                  {file.type === FileType.SHEET &&
                    i18next.t("tenders.form.plication")}
                  {file.type === FileType.CERTIFICATION &&
                    i18next.t("tenders.form.certificate")}
                  {file.type === FileType.BRIEF &&
                    i18next.t("tenders.form.brief")}
                  {file.type === FileType.SERVICE_PLACE &&
                    i18next.t("tenders.form.servicePlace")}
                </a>
              </dd>
            </div>
          </dl>
        </div>
      ))}
    </div>
  );
};

export default OrderFileList;
