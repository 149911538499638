import i18next from "../../../i18n";
import { JointOrdersTable } from "../../../components";
import { OrderState } from "../../../types";
import { useJointOrders } from "../../../hooks/useOrders";

export const Joint = ({
  state,
  title,
}: {
  state: OrderState;
  title?: string;
}) => {
  const { data: jointOrders } = useJointOrders(state);

  return (
    <>
      {jointOrders && jointOrders?.length > 0 && (
        <JointOrdersTable
          title={title ?? i18next.t("order.joint")}
          orders={jointOrders}
        />
      )}
    </>
  );
};
