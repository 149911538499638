import i18next from "../../../i18n";

const InvitedSupplierItem = ({ email }: { email: string }) => {
  return (
    <li>
      <div className="flex items-center px-2 py-3">
        <div className="min-w-0 flex-1 flex items-center">
          <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
            <div className="flex justify-between items-center">
              <p className="flex items-center text-xxs">
                <span className="truncate uppercase text-spectum-dark font-bold">
                  {email}
                </span>
              </p>
            </div>
          </div>
          <span className="px-2 inline-flex text-xxs leading-5 font-semibold rounded bg-green-100 text-green-800 mr-10 uppercase">
            {i18next.t("offer.invited")}
          </span>
        </div>
      </div>
    </li>
  );
};

export default InvitedSupplierItem;
