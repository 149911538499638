import { Fragment } from "react";
import { connect } from "react-redux";
import { KPIs } from "../../components";
import { mapStateToProps } from "../../utils";
import Buyer from "./Buyer/Buyer";
import Seller from "./Seller/Seller";

type HomeProps = {
  isBuy: boolean;
  isAdmin: boolean;
  isBuyer: boolean;
  isSeller: boolean;
  isManager: boolean;
  isAnalyst: boolean;
  isVisualizer: boolean;
};

const Home = ({
  isBuy,
  isAdmin,
  isBuyer,
  isSeller,
  isManager,
  isAnalyst,
  isVisualizer,
}: HomeProps) => {
  return (
    <Fragment>
      <main className="flex-1 px-4">
        {!isVisualizer || !isAdmin ? <KPIs /> : null}
        {isBuy && isAdmin ? <Buyer /> : null}
        {isBuy && isManager ? <Buyer /> : null}
        {isBuy && isBuyer ? <Buyer /> : null}
        {isBuy && isAnalyst ? <Buyer /> : null}
        {isBuy && isVisualizer ? <Buyer /> : null}
        {isSeller ? <Seller /> : null}
      </main>
    </Fragment>
  );
};

export default connect(mapStateToProps)(Home);
