import { useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { IdleTimer } from "./components";
import { RoutesHandler } from "./routes";
import {
  mapDispatchToProps,
  mapStateToProps,
  eraseCookie,
  setCookie,
} from "./utils";
import { UserResponse } from "./types";
import { Maintenance } from "./containers";

type AppProps = {
  isAuthenticated: boolean;
  isResetPassword: boolean;
  isError: boolean;
  onGetUser: () => void;
  onAuthCheckState: (navigate: any, location: any) => void;
  userId: string;
  user: UserResponse;
  onLogout: () => void;
};

function App({
  user,
  userId,
  isError,
  onLogout,
  onGetUser,
  isResetPassword,
  isAuthenticated,
  onAuthCheckState,
}: AppProps) {
  const checkingToken = useRef<string | undefined>();
  const navigate = useNavigate();
  const location = useLocation();

  const isInvitation = new URLSearchParams(location.search).get("redirectURL");
  const isMessage = new URLSearchParams(location.search).get("materialId");

  const handleUserSession = () => {
    onAuthCheckState(navigate, location);
    checkingToken.current = userId;
    if (!checkingToken.current && userId) navigate("/");
    if (userId && isInvitation) navigate(isInvitation);
    if (userId && isInvitation && isMessage)
      navigate(`${isInvitation}&materialId=${isMessage}`);
    if (userId) onGetUser();
  };

  useEffect(() => {
    handleUserSession();
  }, [userId, onGetUser, onAuthCheckState, location.pathname]);

  useEffect(() => {
    if (isError) {
      eraseCookie("accessToken");
      eraseCookie("userId");
      navigate("/");
    }
  }, [isError]);

  useEffect(() => {
    const language = user?.localization || navigator.language;
    setCookie("i18nextLng", language, 7);
  }, [user]);

  useEffect(() => {
    const idleTimer = isAuthenticated
      ? new IdleTimer({
          timeout: 3600,
          onTimeout: () => {
            onLogout();
            eraseCookie("accessToken");
            eraseCookie("userId");
          },
          onExpired: () => {
            onLogout();
            eraseCookie("accessToken");
            eraseCookie("userId");
          },
        })
      : null;

    return () => idleTimer?.cleanUp();
  }, [isAuthenticated, onLogout]);

  useEffect(() => {
    if (isResetPassword && location.pathname !== "/profile") {
      navigate("/profile");
    }
  }, [isResetPassword, location.pathname]);

  if (process.env.REACT_APP_MAINTENANCE === "true") {
    return <Maintenance />;
  }

  return (
    <div className="App">
      <RoutesHandler />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
