import i18next from "../../../i18n";
import { InfoItem, OrderFileList } from "../../../components";
import { formatDate } from "../../../utils";
import { MaterialType } from "../../../types";

const thNormal = [
  { id: 1, name: i18next.t("tenders.tenderSummary.code") },
  { id: 2, name: i18next.t("tenders.tenderSummary.material") },
  { id: 3, name: i18next.t("tenders.tenderSummary.responsible") },
  { id: 4, name: i18next.t("tenders.tenderSummary.volume") },
  { id: 5, name: i18next.t("tenders.tenderSummary.tenderPeriod") },
];

const thService = [
  { id: 1, name: i18next.t("tenders.tenderSummary.code") },
  { id: 2, name: i18next.t("tenders.tenderSummary.material") },
  { id: 3, name: i18next.t("tenders.tenderSummary.responsible") },
  { id: 4, name: i18next.t("tenders.tenderSummary.tenderPeriod") },
];

type MultipleBiddingSummaryProps = {
  orderSummary: any;
  generalBidData: any;
  isBid?: boolean;
};

const MultipleBiddingSummary = ({
  isBid,
  orderSummary,
  generalBidData,
}: MultipleBiddingSummaryProps) => {
  const isService = orderSummary[0]?.type === MaterialType.SERVICE;

  const th = isService ? thService : thNormal;

  return (
    <div className="mt-4">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900 mb-2">
            {i18next.t("purchaseRequests.summary")}
          </h1>
          <div className="grid grid-cols-2 border-t border-b py-2">
            <div className="col-span-1">
              {generalBidData?.name && (
                <InfoItem
                  name={i18next.t("tenders.tenderSummary.name")}
                  description={generalBidData.name}
                />
              )}
            </div>
            <div className="col-span-2">
              <OrderFileList order={generalBidData} />
            </div>
          </div>
        </div>
      </div>
      <div className="-mx-4 flex flex-col sm:-mx-6 md:mx-0">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              {th.map((t) => (
                <th
                  key={t.id}
                  scope="col"
                  className="uppercase py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6 md:pl-0"
                >
                  {t.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {orderSummary?.map((order: any) => (
              <tr key={order.id} className="border-b border-gray-200">
                <td className="py-4 text-xs">
                  <div className="font-medium text-gray-900 uppercase">
                    {isBid ? order?.material?.ean : order.ean}
                  </div>
                </td>
                <td className="py-4 text-xs">
                  <div className="font-medium text-gray-900 uppercase">
                    {isBid
                      ? order?.material?.globalMaterialName
                      : order.globalMaterialName}
                  </div>
                </td>
                <td className="py-4 text-xs text-gray-500">
                  {order?.fullName}
                </td>
                {!isService && (
                  <td className="py-4 text-xs text-gray-500">
                    {order.quantity} {order.measurementUnit}
                  </td>
                )}
                <td className="py-4 text-xs text-gray-500">
                  {order.startSupplyDate === order.endSupplyDate ? (
                    formatDate(order.startSupplyDate)
                  ) : (
                    <>
                      {formatDate(order.startSupplyDate)} -{" "}
                      {formatDate(order.endSupplyDate)}
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MultipleBiddingSummary;
