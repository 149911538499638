import { Fragment, useEffect, useReducer, useState } from "react";
import { connect } from "react-redux";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import {
  CheckCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/solid";
import i18next from "../../i18n";
import InfoItem from "../UI/InfoItem/InfoItem";
import Modal from "../UI/Modal/Modal";
import {
  BIDDING_TYPES,
  IT_DOESNT_HAVE_SPEC,
  NO_SPEC,
  TO_BE_AGREED,
  formatDate,
  labelType,
  mapStateToProps,
} from "../../utils";
import JointOfferForm from "../Form/JointOfferForm";
import Slide from "../UI/Slide/Slide";
import { OfferResponse, OrderResponse, UserResponse } from "../../types";
import OrderFileList from "../OrderFileList/OrderFileList";

const actions = {
  UPDATE_OFFER_PRICE: "UPDATE_OFFER_PRICE",
  UPDATE_OFFER_COMMENT: "UPDATE_OFFER_COMMENT",
  SET_INITIAL_OFFERS: "SET_INITIAL_OFFERS",
};

type JointOrderSummaryProps = {
  user: UserResponse;
  orderSummary: OrderResponse[];
  type: string;
  currency: string;
  measurementUnit: string;
  onCreateMultipleOffer: (request: any) => void;
};

const JointOrderSummary = ({
  user,
  type,
  currency,
  orderSummary,
  measurementUnit,
  onCreateMultipleOffer,
}: JointOrderSummaryProps) => {
  const [open, setOpen] = useState(false);
  const [openSlide, setOpenSlide] = useState(false);
  const [materialToShow, setMaterialToShow] = useState<any>(null);

  const isInverse = type === BIDDING_TYPES.INVERSE_JOINT;

  const [event, updateEvent] = useReducer(
    (state: any, action: any) => {
      let newState = { ...state };
      switch (action.type) {
        case actions.SET_INITIAL_OFFERS:
          newState = {
            ...newState,
            offers: action.payload,
            errors: { ...state.errors },
          };
          break;
        case actions.UPDATE_OFFER_PRICE: {
          if (isInverse) {
            const updatedOffers = newState.offers.map(
              (offer: OfferResponse) => {
                if (offer.order.id === action.id) {
                  if (action.price > offer.initialPrice || action.price < 0) {
                    newState.errors[action.id] = `${i18next.t(
                      "validations.lowerThanX"
                    )} ${offer.initialPrice}`;
                    return { ...offer };
                  } else {
                    delete newState.errors[action.id];
                    return { ...offer, price: action.price };
                  }
                }
                return offer;
              }
            );

            newState = {
              ...newState,
              offers: updatedOffers,
            };
          } else {
            newState = {
              ...newState,
              offers: newState.offers.map((offer: OfferResponse) =>
                offer.order.id === action.id
                  ? { ...offer, price: action.price }
                  : offer
              ),
            };
          }
          return newState;
        }
        case actions.UPDATE_OFFER_COMMENT:
          newState = {
            ...newState,
            offers: newState.offers.map((offer: OfferResponse) =>
              offer.order.id === action.id
                ? { ...offer, comment: action.comment }
                : offer
            ),
          };
          break;
      }
      return newState;
    },
    {
      offers: orderSummary,
      errors: {},
    }
  );

  useEffect(() => {
    const initialState = orderSummary.map((order) => ({
      order: { id: order.id },
      price: 0,
      comment: "",
      user: { id: user?.id },
      initialPrice: order.initialPrice,
      selected: false,
    }));
    updateEvent({ type: actions.SET_INITIAL_OFFERS, payload: initialState });
  }, [orderSummary]);

  let thIsBid = [
    { id: 1, name: i18next.t("tenders.tenderSummary.material") },
    { id: 2, name: i18next.t("tenders.tenderSummary.tenderPeriod") },
    { id: 3, name: i18next.t("tenders.tenderSummary.volume") },
    {
      id: 4,
      name: `${i18next.t(
        "offer.table.price"
      )} - (${currency} / ${measurementUnit}) ${i18next.t(
        "tenders.seller.noTax"
      )}`,
    },
    { id: 5, name: i18next.t("offer.table.total") },
    { id: 6, name: i18next.t("offer.comments") },
  ];

  if (type === BIDDING_TYPES.INVERSE_JOINT) {
    thIsBid = [
      { id: 1, name: i18next.t("tenders.tenderSummary.material") },
      { id: 2, name: i18next.t("tenders.tenderSummary.tenderPeriod") },
      { id: 3, name: i18next.t("tenders.tenderSummary.initialPrice") },
      { id: 4, name: i18next.t("tenders.tenderSummary.percentageDecrement") },
      { id: 5, name: i18next.t("tenders.tenderSummary.volume") },
      {
        id: 6,
        name: `${i18next.t(
          "offer.table.price"
        )} - (${currency} / ${measurementUnit}) ${i18next.t(
          "tenders.seller.noTax"
        )}`,
      },
      { id: 7, name: i18next.t("offer.comments") },
    ];
  }

  const onSubmit = () => {
    const formattedRequest = event.offers
      .filter((offer: OfferResponse) => parseFloat(String(offer.price)) > 0)
      .map((offer: OfferResponse) => ({
        ...offer,
        price: parseFloat(String(offer.price)),
      }));

    onCreateMultipleOffer(formattedRequest);
  };

  const isButtonDisabled =
    Object.keys(event.errors).length > 0 ||
    event.offers.every((offer: OfferResponse) => offer.price === 0) ||
    event.offers.some(
      (offer: OfferResponse) =>
        offer.price === null ||
        offer.price === undefined ||
        String(offer.price) === ""
    );

  return (
    <>
      <Modal
        open={open}
        setOpen={setOpen}
        icon={
          <CheckCircleIcon
            className="h-6 w-6 text-green-600"
            aria-hidden="true"
          />
        }
        title={i18next.t("tenders.seller.offer")}
        message={i18next.t("tenders.seller.question")}
        onClickSuccess={onSubmit}
        onClickCancel={() => setOpen(false)}
      />
      <div className="mt-4">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <InfoItem
              name={i18next.t("tenders.form.company")}
              description={
                <span className="uppercase font-bold text-spectum">
                  {orderSummary[0]?.company?.name}
                </span>
              }
            />
            <InfoItem
              name={i18next.t("tenders.form.fullName")}
              description={orderSummary[0]?.user?.fullName}
            />
            <InfoItem
              name={i18next.t("tenders.form.email")}
              description={orderSummary[0]?.user?.email}
            />
            <InfoItem
              name={i18next.t("tenders.form.phone")}
              description={orderSummary[0]?.user?.phone}
            />
            <InfoItem
              name={i18next.t("tenders.form.tenderType")}
              description={labelType(orderSummary[0]?.type)}
            />
            <InfoItem
              name={i18next.t("tenders.tenderSummary.initialDate")}
              description={formatDate(orderSummary[0]?.startDate)}
            />
            <InfoItem
              name={i18next.t("tenders.tenderSummary.endDate")}
              description={formatDate(orderSummary[0]?.finishDate)}
            />
            <InfoItem
              name={i18next.t("tenders.tenderSummary.paymentMethod")}
              description={orderSummary[0]?.paymentMethod}
            />
            <InfoItem
              name={i18next.t("tenders.tenderSummary.paymentTerm")}
              description={
                <>
                  {orderSummary[0]?.paymentTerm}
                  {orderSummary[0]?.paymentTerm === 1
                    ? ` ${i18next.t("opportunities.table.day")}`
                    : ` ${i18next.t("opportunities.table.days")}`}
                </>
              }
            />
            {orderSummary[0]?.comment && (
              <InfoItem
                name={i18next.t("tenders.tenderSummary.comments")}
                description={orderSummary[0]?.comment}
              />
            )}
            {orderSummary[0]?.files?.length > 0 && (
              <OrderFileList order={orderSummary[0]} />
            )}
            {orderSummary[0]?.plicationUrl &&
              orderSummary[0]?.plicationUrl !== NO_SPEC && (
                <div className="bg-white overflow-hidden">
                  <div className="py-2 sm:p-0">
                    <dl className="sm:divide-y sm:divide-gray-200">
                      <div className="py-2 sm:grid sm:grid-cols-2 sm:gap-4">
                        <dt className="uppercase text-xs font-medium text-gray-500">
                          {i18next.t("newMaterialForm.plication")}
                        </dt>
                        <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-1">
                          <a
                            href={orderSummary[0]?.plicationUrl}
                            target="_blank"
                            rel="noreferrer"
                            className="inline-flex items-center p-1 border border-transparent shadow-sm text-xs leading-4 font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum"
                          >
                            <ArrowDownTrayIcon
                              className="-ml-0.5 mr-2 h-4 w-4"
                              aria-hidden="true"
                            />
                            {i18next.t("cta.plication")}
                          </a>
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              )}
          </div>
        </div>
        <div className="-mx-4 flex flex-col sm:-mx-6 md:mx-0">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                {thIsBid.map((t) => (
                  <th
                    key={t.id}
                    scope="col"
                    className="uppercase py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6 md:pl-0"
                  >
                    {t.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {orderSummary?.map((order) => (
                <tr key={order.id} className="border-b border-gray-200">
                  <td className="py-2 text-xs">
                    <div className="font-semibold text-gray-900 uppercase flex items-center gap-3">
                      {order?.material?.globalMaterialName}
                      <button
                        onClick={() => {
                          setMaterialToShow(order);
                          setOpenSlide(true);
                        }}
                        className="text-xs uppercase underline"
                      >
                        <InformationCircleIcon className="h-5 w-5 text-spectum" />
                      </button>
                    </div>
                  </td>
                  <td className="py-2 text-xs text-gray-500">
                    {order.startSupplyDate === order.endSupplyDate ? (
                      formatDate(order.startSupplyDate)
                    ) : (
                      <>
                        {formatDate(order.startSupplyDate)} -{" "}
                        {formatDate(order.endSupplyDate)}
                      </>
                    )}
                  </td>
                  {type === BIDDING_TYPES.INVERSE_JOINT && (
                    <Fragment>
                      <td className="py-2 text-xs text-gray-900">
                        {order.initialPrice} {order.currency}
                      </td>
                      <td className="py-2 text-xs text-gray-500">
                        {order.minimumDecrement} %
                      </td>
                    </Fragment>
                  )}
                  <td className="py-2 text-xs text-gray-900">
                    {order.quantity} {order.measurementUnit}
                  </td>
                  <JointOfferForm
                    quantity={order.quantity}
                    errors={event.errors}
                    id={order.id}
                    actions={actions}
                    currency={order.currency}
                    orderType={order.type}
                    initialPrice={order?.initialPrice}
                    updateEvent={updateEvent}
                    measurementUnit={order?.measurementUnit}
                  />
                </tr>
              ))}
            </tbody>
          </table>
          <div className="px-2 py-3 bg-gray-50 text-right">
            <button
              onClick={(e) => {
                e.preventDefault();
                setOpen(true);
              }}
              disabled={isButtonDisabled}
              className={`${
                isButtonDisabled
                  ? "bg-gray-200 text-gray-600 cursor-not-allowed"
                  : "bg-spectum hover:bg-spectum-light text-white"
              } inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-xs font-medium rounded focus:outline-none focus:ring-2 focus:ring-offset-2 uppercase`}
            >
              {i18next.t("offer.offerNow")}
            </button>
          </div>
        </div>
      </div>
      <Slide open={openSlide} setOpen={setOpenSlide}>
        <div className="p-2 border rounded shadow">
          <p className="uppercase font-bold text-spectum mb-2">
            {materialToShow?.material?.globalMaterialName}
          </p>
          <InfoItem
            isCol
            name={i18next.t("material.incoTerm")}
            description={materialToShow?.material?.incoTerm}
          />

          {materialToShow?.material?.application && (
            <InfoItem
              isCol
              name={i18next.t("material.application")}
              description={materialToShow?.material?.application}
            />
          )}

          <InfoItem
            isCol
            name={i18next.t("material.deliveryFormat")}
            description={materialToShow?.material?.deliveryFormat}
          />

          <InfoItem
            isCol
            name={i18next.t("material.deliveryPlace")}
            description={
              materialToShow?.material?.deliveryPlace
                ? materialToShow?.material?.deliveryPlace?.address
                : TO_BE_AGREED
            }
          />
          <div className="bg-white overflow-hidden">
            <div className="py-2 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-2 flex flex-col gap-2">
                  <dt className="uppercase text-xs font-medium text-gray-500">
                    {i18next.t("material.specification")}
                  </dt>
                  <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-1">
                    {materialToShow?.material?.fileURL === NO_SPEC ? (
                      <p>{IT_DOESNT_HAVE_SPEC}</p>
                    ) : (
                      <a
                        href={materialToShow?.material?.fileURL}
                        target="_blank"
                        rel="noreferrer"
                        className="inline-flex items-center p-1 border border-transparent shadow-sm text-xs leading-4 font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum"
                      >
                        <ArrowDownTrayIcon
                          className="-ml-0.5 mr-2 h-4 w-4"
                          aria-hidden="true"
                        />
                        {i18next.t("cta.download")}
                      </a>
                    )}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          {materialToShow?.material?.comments && (
            <InfoItem
              isCol
              name={i18next.t("material.comments")}
              description={materialToShow?.material?.comments}
            />
          )}
        </div>
      </Slide>
    </>
  );
};

export default connect(mapStateToProps)(JointOrderSummary);
