import { useState, useMemo } from "react";
import { CheckBadgeIcon, UserPlusIcon } from "@heroicons/react/24/solid";
import { Slide, TooltipSP } from "../../../components";
import i18next from "../../../i18n";
import InviteSupplier from "../../../components/Material/InviteSupplier/InviteSupplier";
import { InvitationResponse } from "../../../types";

type JointSuppliersCheckboxListProps = {
  suppliers: any[];
  invitedSuppliers: InvitationResponse[];
  orderInvitation: any[];
  handleSupplierSelection: (supplier: any) => void;
  ordersToSubmit: any[];
  handleOrdersToSubmit: (order: any, isJoint?: string) => void;
};

const JointSuppliersCheckboxList = ({
  suppliers,
  invitedSuppliers,
  handleSupplierSelection,
  ordersToSubmit,
  orderInvitation,
  handleOrdersToSubmit,
}: JointSuppliersCheckboxListProps) => {
  const [open, setOpen] = useState(false);
  const [materialId, setMaterialId] = useState("");
  const [materialName, setMaterialName] = useState("");

  const handleClick = (id: string, name: string) => {
    setOpen(true);
    setMaterialId(id);
    setMaterialName(name);
  };

  const onCloseInvitation = () => setOpen(false);

  const suppliersObject = Object.keys(suppliers).map((key: any) => {
    return suppliers[key];
  });

  function getUniqueSuppliers(data: any) {
    const uniqueSuppliers = new Map();

    data.forEach((group: any) => {
      group.forEach((entry: any) => {
        const supplier = entry.seller;
        if (supplier && supplier.id) {
          uniqueSuppliers.set(supplier.id, supplier);
        }
      });
    });

    return Array.from(uniqueSuppliers.values());
  }

  const suppliersToShowList = getUniqueSuppliers(suppliersObject);

  const emailsToCheck = useMemo(() => {
    return ordersToSubmit.flatMap((order) =>
      order.suppliers.map((supplier: any) => supplier.emailsList)
    );
  }, [ordersToSubmit]);

  const allSuppliers = ordersToSubmit.map((order) => order?.suppliers).flat();

  return (
    <fieldset className="mt-4 space-y-2">
      <div className="border-t pt-2">
        <div className="flex justify-between">
          {suppliersToShowList && (
            <div>
              {suppliersToShowList.map((supplier) => {
                const isChecked = allSuppliers?.find(
                  (sup) => sup?.id === supplier?.id
                );
                return (
                  <div
                    key={supplier.id}
                    className="relative flex items-start pt-2"
                  >
                    <div className="flex items-center h-5 gap-2">
                      <input
                        id={supplier.id}
                        aria-describedby="comments-description"
                        name={supplier.id}
                        checked={!!isChecked}
                        type="checkbox"
                        className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300 rounded"
                        onChange={() => handleSupplierSelection(supplier)}
                      />
                      <label
                        htmlFor={supplier.id}
                        className="font-medium text-xs text-gray-700 cursor-pointer flex gap-1 items-center"
                      >
                        <span className="text-spectum-dark">
                          {supplier.company.name}
                        </span>
                        {supplier.validated && (
                          <CheckBadgeIcon className="text-blue-500 h-4 w-4" />
                        )}
                      </label>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <div>
            <button
              onClick={() => handleClick("", "")}
              id="invite-supplier-legend"
              className="uppercase flex justify-center items-center p-1 rounded-full border border-spectum shadow-sm text-xs text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light"
            >
              <UserPlusIcon
                className="h-6 w-6 text-spectum-light"
                aria-hidden="true"
              />
            </button>
            <TooltipSP
              id="invite-supplier-legend"
              message={i18next.t("invite.invite")}
            />
          </div>
        </div>
        {invitedSuppliers.length > 0 && (
          <div className="text-xs pt-2">
            {invitedSuppliers.map((inv) => (
              <div key={inv.id} className="relative flex items-start pt-2">
                <div className="flex items-center h-5 gap-2">
                  <input
                    id={inv.id}
                    aria-describedby="comments-description"
                    name={inv.id}
                    type="checkbox"
                    checked={emailsToCheck.includes(inv.email)}
                    className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300 rounded"
                    onChange={() => {
                      const buildInvite = {
                        materialId,
                        emailsList: inv.email,
                      };
                      handleOrdersToSubmit(buildInvite, "isJoint");
                    }}
                  />
                  <label
                    htmlFor={inv.id}
                    className="font-medium text-xs text-gray-700 cursor-pointer flex gap-1 items-center"
                  >
                    <span className="text-spectum-dark">{inv.email}</span>
                  </label>
                </div>
              </div>
            ))}
          </div>
        )}
        {orderInvitation.map((item: any) => {
          const list = item.emailsList.split(":");
          return list.map((email: string) => (
            <p className="text-xs pb-1" key={email}>
              {email}
            </p>
          ));
        })}
      </div>
      <Slide
        open={open}
        setOpen={() => setOpen((prevState: any) => !prevState)}
      >
        <InviteSupplier
          isOrder
          isJoint
          materialId={materialId}
          materialName={materialName}
          onCloseInvitation={onCloseInvitation}
          orderInvitation={orderInvitation}
          handleOrdersToSubmit={handleOrdersToSubmit}
        />
      </Slide>
    </fieldset>
  );
};

export default JointSuppliersCheckboxList;
