import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import {
  ArrowDownTrayIcon,
  CheckBadgeIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { Modal, SampleItem, Toggle } from "..";
import { IT_DOESNT_HAVE_SPEC, NO_SPEC } from "../../utils";
import {
  CertificationResponse,
  CombinedBuyerSellerMaterialOfferResponse,
  SampleEnum,
} from "../../types";

const combineCertifications = (
  materials: CertificationResponse[],
  supplierCerts: any
) => {
  return materials?.map((material) => {
    const supplierCert = supplierCerts?.find(
      (cert: any) => cert.materialCertification.id === material.id
    );
    return {
      ...material,
      hasSupplierCert: !!supplierCert,
    };
  });
};

type MaterialOfferProps = {
  materialOffer: CombinedBuyerSellerMaterialOfferResponse;
  onValidateSeller: (id: string) => void;
  onChangeToggle: () => void;
  onRejectSeller: ({
    buyerSellerId,
    buyerSeller,
  }: {
    buyerSellerId: string;
    buyerSeller: any;
  }) => void;
  setOpen: () => void;
  onApproveSample: ({
    materialOfferId,
    body,
  }: {
    materialOfferId: string;
    body: any;
  }) => void;
  onRequestSample: (id: string) => void;
  onRejectSample: ({
    materialOfferId,
    body,
  }: {
    materialOfferId: string;
    body: any;
  }) => void;
};

const MaterialOffer = ({
  setOpen,
  materialOffer,
  onRejectSeller,
  onChangeToggle,
  onRejectSample,
  onRequestSample,
  onApproveSample,
  onValidateSeller,
}: MaterialOfferProps) => {
  const { t } = useTranslation();
  const [openReject, setOpenReject] = useState(false);
  const [openSample, setOpenSample] = useState(false);
  const [rejectMessage, setRejectMessage] = useState("");

  const userCompanyType = new URLSearchParams(useLocation().search).get(
    "userType"
  );

  const handleOfferConfirmation = () => {
    if (!rejectMessage) return;
    onRejectSeller({
      buyerSellerId: materialOffer?.buyerSeller?.id,
      buyerSeller: {
        ...materialOffer?.buyerSeller,
        comment: rejectMessage,
      },
    });
    setOpenReject(false);
    setOpen();
  };

  const handleSampleRequest = () => {
    onRequestSample(materialOffer.materialOffer.id);
    setOpenSample(false);
  };

  const handleApproveSample = () => {
    onApproveSample({
      materialOfferId: materialOffer.materialOffer.id,
      body: {
        ...materialOffer.materialOffer,
        state: "VERIFIED",
      },
    });
  };

  const handleRejectSample = () => {
    onRejectSample({
      materialOfferId: materialOffer.materialOffer.id,
      body: {
        ...materialOffer.materialOffer,
        state: "REJECTED",
      },
    });
  };

  const onChangeMessage = (e: any) => setRejectMessage(e.target.value);

  const combinedData = combineCertifications(
    materialOffer?.materialOffer?.material?.certifications,
    materialOffer?.materialOffer?.certifications
  );

  return (
    <Fragment>
      <Modal
        isCancel
        hasMessage
        disabled={!rejectMessage}
        open={openReject}
        setOpen={setOpenReject}
        onChangeMessage={onChangeMessage}
        icon={
          <ExclamationTriangleIcon
            className="h-6 w-6 text-red-600"
            aria-hidden="true"
          />
        }
        title={`${t("offer.reject")}: ${
          materialOffer?.buyerSeller?.seller?.company?.name
        }`}
        message={t("offer.question")}
        onClickSuccess={handleOfferConfirmation}
        onClickCancel={() => setOpenReject(false)}
      />
      <Modal
        open={openSample}
        setOpen={setOpenSample}
        icon={
          <ExclamationTriangleIcon
            className="h-6 w-6 text-green-600"
            aria-hidden="true"
          />
        }
        title={`${t("offer.requestFrom")} ${
          materialOffer?.buyerSeller?.seller?.company?.name
        }`}
        message={t("offer.disclaimer")}
        onClickSuccess={handleSampleRequest}
        onClickCancel={() => setOpenSample(false)}
      />
      <div className="relative h-full">
        <div className="shadow p-4 rounded border border-spectum">
          <h4 className="text-xxs font-bold text-gray-600 uppercase">
            {t("offer.title")}
          </h4>
          <h1 className="text-xl font-bold text-gray-900">
            {materialOffer?.materialOffer?.user?.company?.name}
          </h1>
          <div className="bg-white overflow-hidden">
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="py-3">
                <dt className="uppercase text-xxs text-gray-500 pb-1 font-bold">
                  {t("offer.information")}
                </dt>
                <dd className="text-xxs text-gray-900">
                  <span className="uppercase text-xxs font-medium text-gray-500">
                    {t("offer.taxId")}:{" "}
                  </span>
                  {materialOffer?.materialOffer?.user?.company?.taxId} <br />{" "}
                  <br />
                  <span className="uppercase text-xxs font-medium text-gray-500">
                    {t("offer.seller")}:{" "}
                  </span>
                  <span className="font-bold">
                    {materialOffer?.materialOffer?.user?.fullName}
                  </span>
                  <br />
                  <span className="uppercase text-xxs font-medium text-gray-500">
                    {t("offer.email")}:{" "}
                  </span>
                  {materialOffer?.materialOffer?.user?.email} <br />
                  <span className="uppercase text-xxs font-medium text-gray-500">
                    {t("offer.phone")}:{" "}
                  </span>
                  {materialOffer?.materialOffer?.user?.phone} <br />
                </dd>
              </div>
            </dl>
          </div>
          <div className="bg-white overflow-hidden">
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="py-3">
                <dt className="uppercase text-xxs font-medium text-gray-500 pb-1">
                  {t("offer.origin")}
                </dt>
                <dd className="text-xxs text-gray-900">
                  {materialOffer?.materialOffer?.origin?.name}
                </dd>
              </div>
            </dl>
          </div>
          {materialOffer?.materialOffer?.material?.certifications?.length >
            0 && (
            <div className="bg-white overflow-hidden">
              <div className="sm:divide-y sm:divide-gray-200">
                <div className="py-3">
                  <div className="uppercase text-xxs font-medium text-gray-500 pb-1">
                    {t("certifications.certifications")}
                  </div>
                  <div className="text-xxs text-gray-900">
                    {combinedData?.map((cert) => (
                      <div
                        key={cert.id}
                        className="flex items-center gap-2 mb-1"
                      >
                        {cert.hasSupplierCert ? (
                          <CheckBadgeIcon className="h-4 w-4 text-spectum" />
                        ) : (
                          <XMarkIcon className="h-4 w-4 text-red-600" />
                        )}
                        <p>
                          {cert.name}{" "}
                          {cert.required && (
                            <span className="text-red-500">*</span>
                          )}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
          {materialOffer?.materialOffer?.comment && (
            <div className="bg-white overflow-hidden">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-3">
                  <dt className="uppercase text-xxs font-medium text-gray-500 pb-1">
                    {t("offer.comments")}
                  </dt>
                  <dd className="text-xxs text-gray-900">
                    {materialOffer.materialOffer.comment}
                  </dd>
                </div>
              </dl>
            </div>
          )}
          <div className="bg-white overflow-hidden">
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="py-3">
                <dt className="uppercase text-xxs font-medium text-gray-500 pb-1">
                  {t("newMaterialForm.specification")}
                </dt>
                {materialOffer?.materialOffer?.specificationFileUrl ===
                NO_SPEC ? (
                  <p className="text-xxs">{IT_DOESNT_HAVE_SPEC}</p>
                ) : (
                  <dd className="text-xxs text-gray-900">
                    <a
                      href={materialOffer?.materialOffer?.specificationFileUrl}
                      target="_blank"
                      rel="noreferrer"
                      className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-xxs leading-4 font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum"
                    >
                      <ArrowDownTrayIcon
                        className="-ml-0.5 mr-2 h-4 w-4"
                        aria-hidden="true"
                      />
                      {t("cta.download")}
                    </a>
                  </dd>
                )}
              </div>
            </dl>
          </div>
        </div>
        {!userCompanyType ? (
          <div className="pt-4">
            <Toggle
              onChange={() => {
                onChangeToggle();
                onValidateSeller(materialOffer?.buyerSeller?.id);
              }}
              label={
                materialOffer?.buyerSeller?.validated
                  ? t("offer.validated")
                  : t("offer.validateSupplier")
              }
              enabled={materialOffer?.buyerSeller?.validated}
            />
          </div>
        ) : (
          <Fragment>
            {!userCompanyType ? (
              <span className="mt-10 text-xxs font-medium text-gray-500">
                {materialOffer?.buyerSeller?.validated
                  ? t("offer.validated")
                  : t("offer.validateSupplier")}
              </span>
            ) : null}
          </Fragment>
        )}
        <SampleItem
          userCompanyType={userCompanyType}
          stateSample={materialOffer.materialOffer.state}
          handleApproveSample={handleApproveSample}
          handleRejectSample={handleRejectSample}
        />
        {!materialOffer?.buyerSeller?.validated && !userCompanyType ? (
          <div className="absolute bottom-6 right-0 w-full">
            <div className="w-full flex items-center justify-between gap-3">
              {materialOffer.materialOffer.state === SampleEnum.INITIALIZED && (
                <button
                  type="button"
                  className="uppercase px-4 py-2 border border-gray-300 shadow-sm text-xxs font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light"
                  onClick={() => setOpenSample(true)}
                >
                  {t("offer.requestSample")}
                </button>
              )}
              <button
                className="uppercase px-4 py-2 border border-red-300 shadow-sm text-xxs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-300"
                onClick={() => setOpenReject(true)}
              >
                <span>{t("offer.reject")}</span>
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
};

export default MaterialOffer;
