import { Fragment, useMemo, useState } from "react";
import { CheckBadgeIcon, UserPlusIcon } from "@heroicons/react/24/solid";
import { Slide, TooltipSP } from "../../../components";
import i18next from "../../../i18n";
import InviteSupplier from "../../../components/Material/InviteSupplier/InviteSupplier";
import { InvitationResponse } from "../../../types";

const SUPPLIERS_SORT = { true: 1, false: 2 };

type SuppliersCheckboxListProps = {
  suppliers: any;
  invitedSuppliers: InvitationResponse[];
  handleSupplierSelection: any;
  ordersToSubmit: any;
  orderInvitation: any;
  handleOrdersToSubmit: any;
};

const SuppliersCheckboxList = ({
  suppliers,
  invitedSuppliers,
  handleSupplierSelection,
  ordersToSubmit,
  orderInvitation,
  handleOrdersToSubmit,
}: SuppliersCheckboxListProps) => {
  const [open, setOpen] = useState(false);
  const [materialId, setMaterialId] = useState("");
  const [materialName, setMaterialName] = useState("");

  const handleClick = (id: string, name: string) => {
    setOpen(true);
    setMaterialId(id);
    setMaterialName(name);
  };

  const onCloseInvitation = () => setOpen(false);

  const emailsToCheck = useMemo(() => {
    return ordersToSubmit.flatMap((order: any) =>
      order.suppliers.map((supplier: any) => supplier.emailsList)
    );
  }, [ordersToSubmit]);

  const materialsWithoutSuppliers =
    Object.keys(suppliers)
      .map((key, index) => {
        if (ordersToSubmit[index]?.id !== suppliers[key][0]?.material.id) {
          return ordersToSubmit[index];
        }
      })
      .filter((item) => item !== undefined) || [];

  const suppliersToShow = Object.keys(suppliers).map((key, index) => {
    return (
      <div key={key}>
        {suppliers[key].length > 0 && (
          <div className="text-sm border-b flex gap-6 justify-between items-center pb-2 cursor-pointer">
            <label className="text-gray-500 text-xs">
              {suppliers[key][0].material.ean}
              {" - "}
              <span className="uppercase text-spectum font-medium text-sm">
                {suppliers[key][0].material.globalMaterialName}
              </span>
              {" - "}
              {suppliers[key][0].material.deliveryPlace
                ? `(${suppliers[key][0].material.deliveryPlace.name})`
                : `(${i18next.t("messages.toAgree")})`}
            </label>
            <button
              onClick={() =>
                handleClick(
                  suppliers[key][0].material.id,
                  suppliers[key][0].material.globalMaterialName
                )
              }
              id={suppliers[key][0].material.id}
              className="uppercase flex justify-center items-center p-1 rounded-full border border-spectum shadow-sm text-xs text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light"
            >
              <UserPlusIcon
                className="h-6 w-6 text-spectum-light"
                aria-hidden="true"
              />
            </button>
            <TooltipSP
              id={suppliers[key][0].material.id}
              message={i18next.t("invite.invite")}
            />
          </div>
        )}
        {suppliers[key].length > 0 &&
          suppliers[key]
            .sort(
              (a: { validated: boolean }, b: { validated: boolean }) =>
                SUPPLIERS_SORT[`${a.validated}`] -
                SUPPLIERS_SORT[`${b.validated}`]
            )
            .map((supplier: any) => {
              const isChecked = ordersToSubmit[index]?.suppliers?.find(
                (sup: any) => sup?.id === supplier?.seller?.id
              );
              return (
                <div
                  key={supplier.id}
                  className="relative flex items-start pt-2"
                >
                  <div className="flex items-center h-5 gap-2">
                    <input
                      id={supplier.id}
                      aria-describedby="comments-description"
                      name={supplier.id}
                      type="checkbox"
                      checked={!!isChecked}
                      className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300 rounded"
                      onChange={() =>
                        handleSupplierSelection(
                          supplier,
                          suppliers[key][0].material.id
                        )
                      }
                    />
                    <label
                      htmlFor={supplier.id}
                      className="font-medium text-xs text-gray-700 cursor-pointer flex gap-1 items-center"
                    >
                      <span className="text-spectum-dark">
                        {supplier.seller.company.name}
                      </span>
                      {supplier.validated && (
                        <CheckBadgeIcon className="text-blue-500 h-4 w-4" />
                      )}
                    </label>
                  </div>
                </div>
              );
            })}
        {invitedSuppliers.length > 0 && (
          <div className="text-xs pt-2">
            {invitedSuppliers.map((inv) => {
              if (inv.material?.id === suppliers[key][0]?.material.id) {
                return (
                  <div key={inv.id} className="relative flex items-start pt-2">
                    <div className="flex items-center h-5 gap-2">
                      <input
                        id={inv.id}
                        aria-describedby="comments-description"
                        name={inv.id}
                        type="checkbox"
                        checked={emailsToCheck.includes(inv.email)}
                        className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300 rounded"
                        onChange={() => {
                          const buildInvite = {
                            materialId: inv.material?.id,
                            emailsList: inv.email,
                          };
                          handleOrdersToSubmit(buildInvite);
                        }}
                      />
                      <label
                        htmlFor={inv.id}
                        className="font-medium text-xs text-gray-700 cursor-pointer flex gap-1 items-center"
                      >
                        <span className="text-spectum-dark">{inv.email}</span>
                      </label>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        )}
        {suppliers[key].length > 0 && (
          <div className="text-xs pt-2">
            {orderInvitation.map((item: any) => {
              if (item.materialId === suppliers[key][0]?.material.id) {
                const list = item.emailsList.split(":");
                return list.map((email: string) => (
                  <p className="text-xs pb-1" key={email}>
                    {email}
                  </p>
                ));
              }
            })}
          </div>
        )}
      </div>
    );
  });

  return (
    <fieldset className="mt-4 space-y-2">
      {suppliersToShow}

      {materialsWithoutSuppliers.map((order) => (
        <Fragment key={order.id}>
          <div className="text-sm border-b flex gap-6 justify-between items-center pb-2">
            <label className="text-gray-500 text-xs">
              {order.ean}
              {" - "}
              <span className="uppercase text-spectum font-medium text-sm">
                {order.globalMaterialName}
              </span>
              {` - (${order?.deliveryPlace?.name})` ||
                ` - (${i18next.t("messages.toAgree")})`}
            </label>
            <button
              onClick={() => handleClick(order.id, order.globalMaterialName)}
              id={order.id}
              className="uppercase flex justify-center items-center p-1 rounded-full border border-spectum shadow-sm text-xs text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light"
            >
              <UserPlusIcon
                className="h-6 w-6 text-spectum-light"
                aria-hidden="true"
              />
            </button>
            <TooltipSP id={order.id} message={i18next.t("invite.invite")} />
          </div>

          {orderInvitation.length > 0 &&
            orderInvitation.map((item: any) => {
              if (item.materialId === order.id) {
                const list = item.emailsList.split(":");
                return list.map((email: string) => (
                  <p className="text-xs pb-1" key={email}>
                    {email}
                  </p>
                ));
              }
            })}
          {!orderInvitation.find(
            (item: any) => item.materialId === order.id
          ) && (
            <p className="text-black text-xs mt-2">
              {i18next.t("invite.without")}
            </p>
          )}
        </Fragment>
      ))}
      <Slide open={open} setOpen={() => setOpen((prevState) => !prevState)}>
        <InviteSupplier
          isOrder
          materialId={materialId}
          materialName={materialName}
          onCloseInvitation={onCloseInvitation}
          orderInvitation={orderInvitation}
          handleOrdersToSubmit={handleOrdersToSubmit}
        />
      </Slide>
    </fieldset>
  );
};

export default SuppliersCheckboxList;
