import { Fragment, useState } from "react";
import i18next from "../../../i18n";
import {
  OpportunitiesTable,
  Loader,
  JointOrdersTable,
  UserMaterialOpportunitiesTable,
} from "../../../components";
import { ORDER_TYPES } from "../../../types";
import {
  useMultipleOpportunities,
  useOpportunities,
} from "../../../hooks/useOpportunities";
import {
  useInvitationsByEmail,
  useUserMaterialOpportunitiesInvitations,
} from "../../../hooks/useMaterialOpportunities";

export default function Seller() {
  const [pageMaterialInvitationsNumber, setPageMaterialInvitationsNumber] =
    useState(0);
  const [pageInvitationsNumber, setPageInvitationsNumber] = useState(0);

  const opportunities = useOpportunities();

  const multipleOpportunities = useMultipleOpportunities(
    ORDER_TYPES.JOINT,
    ORDER_TYPES.INVERSE_JOINT
  );

  const { data: materialInvitationsOpportunities } =
    useUserMaterialOpportunitiesInvitations(pageMaterialInvitationsNumber);

  const { data: invitationsByEmail } = useInvitationsByEmail(
    pageInvitationsNumber
  );

  return (
    <Fragment>
      {opportunities?.isLoading ? (
        <div className="py-2">
          <Loader />
        </div>
      ) : (
        <Fragment>
          {materialInvitationsOpportunities?.content?.length > 0 && (
            <UserMaterialOpportunitiesTable
              pageNumber={pageMaterialInvitationsNumber}
              setPageNumber={setPageMaterialInvitationsNumber}
              materialInvitationsOpportunities={
                materialInvitationsOpportunities
              }
            />
          )}

          {invitationsByEmail?.content?.length > 0 && (
            <UserMaterialOpportunitiesTable
              pageNumber={pageInvitationsNumber}
              setPageNumber={setPageInvitationsNumber}
              materialInvitationsOpportunities={invitationsByEmail}
            />
          )}

          {opportunities?.data?.length > 0 && (
            <OpportunitiesTable
              title={i18next.t("material.tenders")}
              opportunities={opportunities?.data}
            />
          )}
          {multipleOpportunities.data &&
            multipleOpportunities.data.length > 0 && (
              <JointOrdersTable
                orders={multipleOpportunities.data}
                title={i18next.t("order.joint")}
              />
            )}
        </Fragment>
      )}
    </Fragment>
  );
}
