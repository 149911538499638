import { useTranslation } from "react-i18next";

type BannerProps = {
  isNew?: boolean;
  isPending?: boolean;
  bannerMessage?: string;
  isResetPassword: boolean;
};

const Banner = ({
  isNew = false,
  isPending = false,
  bannerMessage,
  isResetPassword,
}: BannerProps) => {
  const { t } = useTranslation();

  // Determine the message to display
  const getMessage = (): string => {
    if (bannerMessage) return bannerMessage;
    if (isPending) return t("banner.check");
    if (isNew) return t("banner.user");
    if (isResetPassword) return t("banner.password");
    return "";
  };

  return (
    <div className="relative bg-blue-500">
      <div className="container mx-auto max-w-7xl py-3 px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-center text-center">
          <p className="font-medium text-white">
            <span>{getMessage()}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Banner;
