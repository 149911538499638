import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { OrdersTable, Loader } from "../../../components";
import { ORDER_TYPES, OrderState } from "../../../types";
import { useOrdersByTypeAndState } from "../../../hooks/useOrders";
import { Joint } from "../../Orders/Joint/Joint";

export default function Buyer() {
  const { t } = useTranslation();
  const [closedPageNumber, setClosedPageNumber] = useState(0);

  const { data: orders, isLoading } = useOrdersByTypeAndState(
    OrderState.CLOSED,
    ORDER_TYPES.NORMAL,
    ORDER_TYPES.INVERSE,
    closedPageNumber
  );

  return (
    <Fragment>
      {isLoading ? (
        <div className="py-2">
          <Loader />
        </div>
      ) : (
        <Fragment>
          {orders && orders?.content?.length > 0 ? (
            <OrdersTable
              title={t("order.closed")}
              orders={orders}
              pageNumber={closedPageNumber}
              setPageNumber={setClosedPageNumber}
            />
          ) : null}

          <Joint state={OrderState.CLOSED} title={t("order.jointClosed")} />
        </Fragment>
      )}
    </Fragment>
  );
}
