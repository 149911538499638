import { Fragment } from "react";
import i18next from "../../i18n";
import { InfoItem, OrderFileList } from "../../components";
import { BIDDING_TYPES, formatDate, labelType } from "../../utils";
import { ORDER_TYPES, OrderResponse } from "../../types";

const thInverse = [
  { id: 1, name: i18next.t("tenders.tenderSummary.code") },
  { id: 2, name: i18next.t("tenders.tenderSummary.material") },
  { id: 3, name: i18next.t("tenders.tenderSummary.volume") },
  { id: 4, name: i18next.t("tenders.tenderSummary.initialPrice") },
  { id: 5, name: i18next.t("tenders.tenderSummary.percentageDecrement") },
  { id: 6, name: i18next.t("tenders.tenderSummary.tenderPeriod") },
];

const thNormal = [
  { id: 1, name: i18next.t("tenders.tenderSummary.code") },
  { id: 2, name: i18next.t("tenders.tenderSummary.material") },
  { id: 3, name: i18next.t("tenders.tenderSummary.volume") },
  { id: 4, name: i18next.t("tenders.tenderSummary.tenderPeriod") },
];

type BiddingSummaryProps = {
  order: OrderResponse;
};

const BiddingSummary = ({ order }: BiddingSummaryProps) => {
  let tableTh = thNormal;

  if (order?.type === ORDER_TYPES.INVERSE) {
    tableTh = thInverse;
  }

  return (
    <div className="mt-4">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <InfoItem
            name={i18next.t("tenders.form.tenderType")}
            description={labelType(order?.type)}
          />
          <InfoItem
            name={i18next.t("tenders.form.internalCode")}
            description={
              <span className="font-bold uppercase">{order?.internalCode}</span>
            }
          />
          <InfoItem
            name={i18next.t("tenders.tenderSummary.initialDate")}
            description={formatDate(order?.startDate)}
          />
          <InfoItem
            name={i18next.t("tenders.tenderSummary.endDate")}
            description={formatDate(order?.finishDate)}
          />
          <InfoItem
            name={i18next.t("tenders.tenderSummary.paymentMethod")}
            description={order?.paymentMethod}
          />
          <InfoItem
            name={i18next.t("tenders.tenderSummary.paymentTerm")}
            description={`${order.paymentTerm} ${
              order.paymentTerm === 1
                ? ` ${i18next.t("opportunities.table.day")}`
                : ` ${i18next.t("opportunities.table.days")}`
            }`}
          />
          {order?.comment && (
            <InfoItem
              name={i18next.t("tenders.tenderSummary.comments")}
              description={order?.comment}
            />
          )}
          {order.files.length > 0 && <OrderFileList order={order} />}
        </div>
      </div>
      <div className="-mx-4 flex flex-col sm:-mx-6 md:mx-0">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              {tableTh.map((t) => (
                <th
                  key={t.id}
                  scope="col"
                  className="uppercase py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6 md:pl-0"
                >
                  {t.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr key={order.id} className="border-b border-gray-200">
              <td className="py-4 text-xs">
                <div className="font-medium text-gray-900 uppercase">
                  {order?.material?.ean}
                </div>
              </td>
              <td className="py-4 text-xs">
                <div className="font-medium text-gray-900 uppercase">
                  {order?.material?.globalMaterialName}
                </div>
              </td>
              <td className="py-4 text-xs text-gray-500">
                {order.quantity} {order.measurementUnit}
              </td>
              {order?.type === BIDDING_TYPES.INVERSE && (
                <Fragment>
                  <td className="py-4 text-xs text-gray-500">
                    {order.initialPrice} {order.material.coin}
                  </td>
                  <td className="py-4 text-xs text-gray-500">
                    {order.minimumDecrement} %
                  </td>
                </Fragment>
              )}
              <td className="py-4 text-xs text-gray-500">
                {order.startSupplyDate === order.endSupplyDate ? (
                  formatDate(order.startSupplyDate)
                ) : (
                  <>
                    {formatDate(order.startSupplyDate)} -{" "}
                    {formatDate(order.endSupplyDate)}
                  </>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BiddingSummary;
