import { CompanyResponse } from "./company.type";
import i18next from "../i18n";

export enum UserType {
  BUYER = "BUYER",
  SELLER = "SELLER",
  ADMIN = "ADMIN",
}

export enum UserRole {
  USER = "USER",
  BUYER = "BUYER",
  SELLER = "SELLER",
  ADMIN = "ADMIN",
  RESET_PASSWORD = "RESET_PASSWORD",
  BLOCKED = "BLOCKED",
  ADMIN_APPROVAL = "ADMIN_APPROVAL",
}

export enum UserCompanyType {
  BUYER = "BUYER",
  MANAGER = "MANAGER",
  ANALYST = "ANALYST",
  VISUALIZER = "VISUALIZER",
  COMMERCIAL = "COMMERCIAL",
  REQUESTER = "REQUESTER",
  DEFAULT = "DEFAULT",
  ADMIN = "ADMIN",
}

export const userCompanyTypeMap = {
  [UserCompanyType.BUYER]: i18next.t("userTypes.buyer"),
  [UserCompanyType.MANAGER]: i18next.t("userTypes.manager"),
  [UserCompanyType.ANALYST]: i18next.t("userTypes.analyst"),
  [UserCompanyType.VISUALIZER]: i18next.t("userTypes.visualizer"),
  [UserCompanyType.REQUESTER]: i18next.t("userTypes.requester"),
  [UserCompanyType.ADMIN]: i18next.t("userTypes.admin"),
  [UserCompanyType.COMMERCIAL]: i18next.t("userTypes.commercial"),
};

export interface UserResponse {
  createdAt: string; // ISO 8601 date string
  updatedAt: string; // ISO 8601 date string
  id: string; // UUID
  fullName: string;
  email: string;
  phone: string;
  imageUrl: string | null;
  type: UserType;
  userCompanyType: UserCompanyType;
  role: UserRole;
  localization: string; // Could be a language code like "es" or "en"
  company: CompanyResponse;
  parent?: UserResponse; // Assuming this refers to a parent user or entity
  active: boolean;
  compliant: boolean;
}
