export enum FileType {
  ATTACHMENT = "ATTACHMENT",
  CERTIFICATION = "CERTIFICATION",
  SHEET = "SHEET",
  BRIEF = "BRIEF",
  SERVICE_PLACE = "SERVICE_PLACE",
}

export interface FileResponse {
  createdAt: string;
  fileURL: string;
  id: string;
  name: string;
  type: FileType;
  updatedAt: string;
}

export interface OrderFile {
  fileName: string;
  type: string;
  name: string;
  fileURL: string;
}
