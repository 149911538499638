import { Fragment } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import i18next from "../../../i18n";
import { useLanguage } from "../../../hooks";
import { CategoryResponse } from "../../../types";

type CategoriesBuilderProps = {
  buyTeams: CategoryResponse[];
  register: any;
  setBuyTeamSelected: (code: string) => void;
  setProductServiceFamilySelected: (code: string) => void;
  setProductServiceClassSelected: (code: string) => void;
  errors: any;
  buyTeamSelected: string;
  isLoadingCategory: boolean;
  productServiceFamily: CategoryResponse[];
  productServiceClass: CategoryResponse[];
  productServiceFamilySelected: string;
  productServiceClassSelected: string;
};

export default function CategoriesBuilder({
  buyTeams,
  register,
  setBuyTeamSelected,
  setProductServiceFamilySelected,
  setProductServiceClassSelected,
  errors,
  buyTeamSelected,
  isLoadingCategory,
  productServiceFamily,
  productServiceClass,
  productServiceFamilySelected,
  productServiceClassSelected,
}: CategoriesBuilderProps) {
  const langEs = useLanguage();
  return (
    <Fragment>
      <div className="col-span-4 md:col-span-1">
        <select
          {...register("buy_team", { required: true })}
          id="buy_team"
          name="buy_team"
          className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded"
          disabled={!buyTeams}
          onChange={(e) => {
            setBuyTeamSelected(e.target.value);
            setProductServiceFamilySelected("");
            setProductServiceClassSelected("");
          }}
        >
          <option value="">{i18next.t("material.buyTeam")}</option>
          {buyTeams?.map((team) => (
            <option key={team.id} value={team.id}>
              {langEs ? team.name : team.nameEn}
            </option>
          ))}
        </select>
        {errors?.buy_team && !buyTeamSelected && (
          <span className="text-xs text-red-500">
            {errors?.buy_team?.message}
          </span>
        )}
      </div>

      <div className="col-span-4 md:col-span-1">
        <select
          {...register("product_family", { required: true })}
          id="product_family"
          name="product_family"
          className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded"
          disabled={productServiceFamily?.length === 0}
          onChange={(e) => {
            setProductServiceFamilySelected(e.target.value);
            setProductServiceClassSelected("");
          }}
        >
          <option value="">{i18next.t("material.productFamily")}</option>
          {productServiceFamily?.map((team) => (
            <option key={team.id} value={team.id}>
              {langEs ? team.name : team.nameEn}
            </option>
          ))}
        </select>
        {errors?.product_family && !productServiceFamilySelected && (
          <span className="text-xs text-red-500">
            {errors?.product_family?.message}
          </span>
        )}
      </div>

      <div className="col-span-4 md:col-span-1">
        <select
          {...register("product_class", { required: true })}
          id="product_class"
          name="product_class"
          className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded"
          disabled={productServiceClass?.length === 0}
          onChange={(e) => {
            setProductServiceClassSelected(e.target.value);
          }}
        >
          <option value="">{i18next.t("material.productClass")}</option>
          {productServiceClass?.map((team) => (
            <option key={team.id} value={team.id}>
              {langEs ? team.name : team.nameEn}
            </option>
          ))}
        </select>
        {errors?.product_class && !productServiceClassSelected && (
          <span className="text-xs text-red-500">
            {errors?.product_class?.message}
          </span>
        )}
      </div>

      {isLoadingCategory && (
        <div className="flex w-full items-center justify-center">
          <ClipLoader loading size={30} />
        </div>
      )}
    </Fragment>
  );
}
