import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

const buttonStyles = {
  default:
    "px-4 flex justify-center items-center border border-transparent shadow-sm text-xs font-medium rounded text-white bg-spectum-dark hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 uppercase h-9",
  danger:
    "px-4 flex justify-center items-center border border-red-600 shadow-sm text-xs font-medium rounded bg-red-200 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 uppercase h-9",
  disabled:
    "px-4 flex justify-center items-center border border-transparent shadow-sm text-xs font-medium rounded text-white bg-gray-500 opacity-50 cursor-not-allowed focus:outline-none uppercase h-9",
  success:
    "px-4 flex justify-center items-center border border-transparent shadow-sm text-xs font-medium rounded text-white bg-green-800 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 uppercase h-9",
  cancel:
    "px-4 flex justify-center items-center border shadow-sm text-xs font-medium rounded text-black bg-white border-black focus:outline-none focus:ring-2 focus:ring-offset-2 uppercase h-9",
  add: "px-4 flex justify-center items-center border text-gray-700 border-gray-700 shadow-sm text-xs font-medium rounded bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 uppercase h-9",
};

interface ButtonProps {
  children: ReactNode;
  onClick?: (e: any) => void;
  type?: "button" | "submit" | "reset";
  variant?: keyof typeof buttonStyles;
  disabled?: boolean;
  link?: boolean;
  to?: string;
  ref?: React.Ref<HTMLButtonElement | HTMLAnchorElement>;
  style?: string;
}

const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  type = "button",
  variant = "default",
  disabled = false,
  link = false,
  to = "",
  ref,
  style,
}) => {
  return link ? (
    <Link
      to={to}
      className={buttonStyles[variant]}
      ref={ref as React.Ref<HTMLAnchorElement>}
    >
      {children}
    </Link>
  ) : (
    <button
      ref={ref as React.Ref<HTMLButtonElement>}
      disabled={disabled}
      type={type}
      onClick={onClick}
      className={`${buttonStyles[variant]} ${style}`}
    >
      {children}
    </button>
  );
};

export default Button;
