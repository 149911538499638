import { useState } from "react";
import { CheckBadgeIcon, UserPlusIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";
import OffersReceivedNotValidated from "../OffersReceivedNotValidated/OffersReceivedNotValidated";
import OffersReceivedValidated from "../OffersReceivedValidated/OffersReceivedValidated";
import PotencialSuppliers from "../PotencialSuppliers/PotencialSuppliers";
import Pagination from "../../UI/Pagination/Pagination";
import TooltipSP from "../../UI/TooltipSP/TooltipSP";
import { useLanguage } from "../../../hooks";
import { Loader } from "../..";
import {
  CombinedBuyerSellerMaterialOfferResponse,
  CountryResponse,
} from "../../../types";
import { useNewMaterialCompanyOpportunitiesByCountry } from "../../../hooks/useMaterialOpportunities";
import { useInvitedMaterialSuppliers } from "../../../hooks/useSuppliers";
import InvitedSupplierItem from "../InvitedSupplierItem/InvitedSupplierItem";

type SuppliersProps = {
  materialId: string;
  countryId: string;
  onClick: () => void;
  isActive: boolean;
  materialOffers: CombinedBuyerSellerMaterialOfferResponse[];
  setOpen: (b: boolean) => void;
  setMaterialOfferToShow: (b: CombinedBuyerSellerMaterialOfferResponse) => void;
  setPotencialSupplierToShow: (b: boolean) => void;
  setOpenChat: (b: boolean) => void;
  setOpenSupplier: (b: boolean) => void;
  countriesByMaterial?: CountryResponse[];
};

export default function Suppliers({
  materialId,
  countryId,
  setOpen,
  onClick,
  isActive,
  setOpenChat,
  materialOffers,
  setOpenSupplier,
  countriesByMaterial,
  setMaterialOfferToShow,
  setPotencialSupplierToShow,
}: SuppliersProps) {
  const [selectedCountry, setSelectedCountry] = useState(countryId);
  const [page, setPage] = useState(0);
  const { t } = useTranslation();
  const langEs = useLanguage();

  const {
    data: materialCompaniesOpportunitiesByCountry,
    isLoading: isLoadingMaterialOpportunities,
  } = useNewMaterialCompanyOpportunitiesByCountry(
    materialId,
    selectedCountry,
    page
  );

  const { data: invitedMaterialSuppliers } =
    useInvitedMaterialSuppliers(materialId);

  return (
    <div className="col-span-2 lg:col-span-1 xl:pr-8 xl:border-r xl:border-gray-200">
      <aside className="xl:pl-8 xl:col-span-2">
        <div className="space-y-4">
          <div className="flex justify-between border-b pb-3">
            <h2 className="text-xxs font-medium flex items-center gap-4 uppercase">
              <span>{t("material.validatedSuppliers")}</span>
              <CheckBadgeIcon
                className="h-5 w-5 text-blue-500"
                aria-hidden="true"
              />
            </h2>
            {isActive ? (
              <>
                <button
                  onClick={onClick}
                  id="invite-supplier-legends"
                  className="uppercase flex justify-center items-center p-1 rounded-full border border-spectum shadow-sm text-xxs text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light"
                >
                  <UserPlusIcon
                    className="h-6 w-6 text-spectum-light"
                    aria-hidden="true"
                  />
                </button>
                <TooltipSP
                  id="invite-supplier-legends"
                  message={t("invite.invite")}
                />
              </>
            ) : null}
          </div>
          <OffersReceivedValidated
            materialOffers={materialOffers}
            setOpen={setOpen}
            setMaterialOfferToShow={setMaterialOfferToShow}
            setOpenChat={setOpenChat}
          />
          {invitedMaterialSuppliers && invitedMaterialSuppliers.length > 0 && (
            <div className="bg-white shadow overflow-hidden rounded border">
              <ul className="divide-y divide-gray-200">
                {invitedMaterialSuppliers.map((invitedSupplier) => (
                  <InvitedSupplierItem
                    key={invitedSupplier.id}
                    email={invitedSupplier.email}
                  />
                ))}
              </ul>
            </div>
          )}
          <h2 className="text-xxs font-medium border-b pb-3 uppercase">
            {t("material.alternativeSuppliers")}
          </h2>
          <OffersReceivedNotValidated
            materialOffers={materialOffers}
            setOpen={setOpen}
            setMaterialOfferToShow={setMaterialOfferToShow}
            setOpenChat={setOpenChat}
          />
          <div className="border-b pb-3 flex justify-between items-center">
            <h2 className="text-xxs font-medium uppercase">
              {t("material.potencialSuppliers")}
            </h2>
            {countriesByMaterial && countriesByMaterial?.length > 0 && (
              <div>
                <select
                  id="origin"
                  name="origin"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xxs rounded"
                  onChange={(e) => setSelectedCountry(e.target.value)}
                  defaultValue={selectedCountry}
                >
                  {countriesByMaterial?.map((country) => (
                    <option key={country.id} value={country.id}>
                      {langEs ? country.name : country.nameEn}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
          {isLoadingMaterialOpportunities ? (
            <Loader />
          ) : (
            <>
              <PotencialSuppliers
                setOpen={setOpenSupplier}
                materialCompaniesOpportunities={
                  materialCompaniesOpportunitiesByCountry
                }
                setPotencialSupplierToShow={setPotencialSupplierToShow}
              />

              {materialCompaniesOpportunitiesByCountry?.totalElements > 10 && (
                <Pagination
                  results={
                    materialCompaniesOpportunitiesByCountry.numberOfElements
                  }
                  total={materialCompaniesOpportunitiesByCountry.totalElements}
                  amountOfPages={
                    materialCompaniesOpportunitiesByCountry.totalPages
                  }
                  currentPage={page}
                  setPageNumber={setPage}
                />
              )}
            </>
          )}
        </div>
      </aside>
    </div>
  );
}
