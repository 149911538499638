import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import {
  ArrowDownTrayIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import {
  mapStateToProps,
  mapDispatchToProps,
  cutIdOnFirstHyphen,
  TO_BE_AGREED,
  NO_SPEC,
  IT_DOESNT_HAVE_SPEC,
} from "../../utils";
import {
  GoBackArrow,
  Loader,
  Button,
  Modal,
  Slide,
  InfoItem,
} from "../../components";
import { useID } from "../../hooks";
import BidHeader from "../Bid/BidHeader";
import PurchaseRequestSummaryDetail from "./PurchaseRequestSummaryDetail/PurchaseRequestSummaryDetail";
import {
  useDeletePurchaseRequest,
  usePurchaseRequest,
  useRejectPurchaseRequest,
  useUpdatePurchaseRequest,
} from "../../hooks/usePurchaseRequests";
import { MaterialResponse, OrderState } from "../../types";

const PurchaseRequestDetail = ({
  isRequester,
  onOpenNotification,
}: {
  isRequester: boolean;
  onOpenNotification: (message: string) => void;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const id = useID();
  const [openReject, setOpenReject] = useState(false);
  const [openSend, setOpenSend] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [rejectMessage, setRejectMessage] = useState("");
  const [openSlide, setOpenSlide] = useState(false);
  const [materialToShow, setMaterialToShow] = useState<MaterialResponse>();

  const {
    data: purchaseRequest,
    isLoading: isLoadingPurchaseRequests,
    isError: purchaseRequestError,
  } = usePurchaseRequest(id);

  const { mutate: deletePurchaseRequest, isPending: isDeleting } =
    useDeletePurchaseRequest(
      () => {
        onOpenNotification(t("purchaseRequests.deleteMessage"));
        navigate("/");
      },
      () => onOpenNotification(t("newMaterialForm.error"))
    );

  const { mutate: rejectPurchaseRequest, isPending: isRejecting } =
    useRejectPurchaseRequest(
      () => {
        onOpenNotification(t("purchaseRequests.rejectedSuccess"));
        navigate("/purchase-requests");
      },
      () => onOpenNotification(t("newMaterialForm.error"))
    );

  const { mutate: updatePurchaseRequest } = useUpdatePurchaseRequest(
    () => {
      onOpenNotification(t("purchaseRequests.sendSuccess"));
      navigate("/");
    },
    () => onOpenNotification(t("newMaterialForm.error"))
  );

  const onChangeMessage = (e: React.ChangeEvent<HTMLInputElement>) =>
    setRejectMessage(e.target.value);

  const handleReject = () => {
    if (!rejectMessage) return;
    rejectPurchaseRequest({
      purchaseRequestId: id,
      body: { comment: rejectMessage },
    });
    setOpenReject(false);
  };

  const handleDelete = () => {
    deletePurchaseRequest(id);
    setOpenDelete(false);
  };

  const handleUpdate = () => {
    updatePurchaseRequest([
      {
        ...purchaseRequest.order,
        user: { id: purchaseRequest.order.user.id },
        company: { id: purchaseRequest.order.company.id },
        deliveryPlace: purchaseRequest?.order?.deliveryPlace
          ? { id: purchaseRequest.order.deliveryPlace.id }
          : null,
        state: OrderState.DELIVERED,
        comment: null,
      },
    ]);
    setOpenSend(false);
  };

  if (purchaseRequestError) navigate("/not-found");

  if (purchaseRequest && purchaseRequest?.order?.state === OrderState.OPEN) {
    navigate(`/order/${purchaseRequest?.order?.id}`);
  }

  return (
    <Fragment>
      <Modal
        open={openSend}
        setOpen={setOpenSend}
        icon={
          <CheckCircleIcon
            className="h-6 w-6 text-green-600"
            aria-hidden="true"
          />
        }
        title={t("purchaseRequests.send")}
        message={t("purchaseRequests.sendMessage")}
        onClickSuccess={handleUpdate}
        onClickCancel={() => setOpenSend(false)}
      />
      <Modal
        open={openReject}
        setOpen={setOpenReject}
        hasMessage
        disabled={!rejectMessage}
        onChangeMessage={onChangeMessage}
        icon={
          <ExclamationTriangleIcon
            className="h-6 w-6 text-red-600"
            aria-hidden="true"
          />
        }
        title={t("purchaseRequests.reject")}
        message={t("purchaseRequests.rejectQuestion")}
        isCancel
        onClickSuccess={handleReject}
        onClickCancel={() => setOpenReject(false)}
      />
      <Modal
        open={openDelete}
        setOpen={setOpenDelete}
        hasMessage
        disabled={!rejectMessage}
        onChangeMessage={onChangeMessage}
        icon={
          <ExclamationTriangleIcon
            className="h-6 w-6 text-red-600"
            aria-hidden="true"
          />
        }
        title={t("purchaseRequests.modalTitleDelete")}
        message={t("purchaseRequests.modalDelete")}
        isCancel
        onClickSuccess={handleDelete}
        onClickCancel={() => setOpenDelete(false)}
      />
      {isLoadingPurchaseRequests || isDeleting || isRejecting ? (
        <Loader isFullScreen />
      ) : (
        <div className="min-h-full flex">
          <div className="flex flex-col w-0 flex-1">
            <div className="py-8 xl:py-10 relative">
              <div className="w-full px-8 flex justify-between items-center gap-4">
                <GoBackArrow />
                {isRequester ? (
                  <div className="flex gap-2 items-center">
                    {(purchaseRequest?.order?.state === OrderState.DRAFT ||
                      purchaseRequest?.order?.state === OrderState.DELIVERED ||
                      purchaseRequest?.order?.state ===
                        OrderState.REJECTED) && (
                      <>
                        <Button
                          onClick={() => setOpenDelete(true)}
                          variant="danger"
                        >
                          {t("purchaseRequests.delete")}
                        </Button>
                        <Button
                          link
                          to={`/new-purchase-request?purchaseRequestId=${purchaseRequest?.id}`}
                          variant="cancel"
                        >
                          {t("cta.edit")}
                        </Button>
                        {purchaseRequest?.order?.state !==
                          OrderState.DELIVERED && (
                          <Button onClick={() => setOpenSend(true)}>
                            {t("cta.send")}
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                ) : (
                  <div className="flex gap-2 items-center">
                    <Button
                      onClick={() => setOpenReject(true)}
                      variant="danger"
                    >
                      {t("purchaseRequests.reject")}
                    </Button>
                    <Button
                      link
                      to={`/order-by-request?purchaseRequestId=${id}`}
                    >
                      {t("cta.launch")}
                    </Button>
                  </div>
                )}
              </div>
              <div className="mx-auto px-4 lg:px-8 xl:grid xl:grid-cols-3 gap-4">
                <div className="col-span-3">
                  <h1 className="font-bold text-spectum uppercase mb-4">
                    {t("tenders.form.internalCode")}
                    {": "}
                    {purchaseRequest && cutIdOnFirstHyphen(purchaseRequest?.id)}
                  </h1>
                  <BidHeader
                    order={purchaseRequest?.order}
                    orderName={purchaseRequest?.order?.name}
                  />
                </div>
                <PurchaseRequestSummaryDetail
                  setMaterialToShow={setMaterialToShow}
                  setOpenSlide={setOpenSlide}
                  purchaseRequest={purchaseRequest}
                  type={purchaseRequest?.type}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <Slide open={openSlide} setOpen={setOpenSlide}>
        <div className="p-2 border rounded shadow">
          <p className="uppercase font-bold text-spectum mb-2">
            {materialToShow?.globalMaterialName}
          </p>
          {materialToShow?.incoTerm && (
            <InfoItem
              isCol
              name={t("material.incoTerm")}
              description={materialToShow?.incoTerm}
            />
          )}
          {materialToShow?.application && (
            <InfoItem
              isCol
              name={t("material.application")}
              description={materialToShow?.application}
            />
          )}
          <InfoItem
            isCol
            name={t("material.deliveryFormat")}
            description={materialToShow?.deliveryFormat}
          />
          <InfoItem
            isCol
            name={t("material.currency")}
            description={materialToShow?.coin}
          />
          <InfoItem
            isCol
            name={t("material.deliveryPlace")}
            description={
              materialToShow?.deliveryPlace
                ? materialToShow?.deliveryPlace?.name
                : TO_BE_AGREED
            }
          />
          <div className="bg-white overflow-hidden">
            <div className="py-2 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-2 flex flex-col gap-2">
                  <dt className="uppercase text-xs font-medium text-gray-500">
                    {t("material.specification")}
                  </dt>
                  <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-1">
                    {materialToShow?.fileURL === NO_SPEC ? (
                      <p>{IT_DOESNT_HAVE_SPEC}</p>
                    ) : (
                      <a
                        href={materialToShow?.fileURL}
                        target="_blank"
                        rel="noreferrer"
                        className="inline-flex items-center p-1 border border-transparent shadow-sm text-xs leading-4 font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum"
                      >
                        <ArrowDownTrayIcon
                          className="-ml-0.5 mr-2 h-4 w-4"
                          aria-hidden="true"
                        />
                        {t("cta.download")}
                      </a>
                    )}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          {materialToShow?.comments && (
            <InfoItem
              isCol
              name={t("material.comments")}
              description={materialToShow?.comments}
            />
          )}
        </div>
      </Slide>
    </Fragment>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchaseRequestDetail);
