import { Fragment, useState } from "react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { mapStateToProps, mapDispatchToProps } from "../../utils";
import {
  GoBackArrow,
  Loader,
  Modal,
  Slide,
  Chat,
  OrderSummary,
} from "../../components";
import { useID } from "../../hooks";
import { UserResponse } from "../../types";
import { useOrder } from "../../hooks/useOrders";
import {
  useCreateChat,
  useCreateMessage,
  useOrderChat,
} from "../../hooks/useChat";
import { useCreateOffer, useUserOffer } from "../../hooks/useOffers";
import { queryKeys } from "../../hooks/queryKeys";
import BidHeader from "./BidHeader";

type IsSellerBidProps = {
  user: UserResponse;
  onOpenNotification: (message: string) => void;
};

const IsSellerBid = ({ user, onOpenNotification }: IsSellerBidProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const id = useID();
  const [open, setOpen] = useState(false);
  const [openChat, setOpenChat] = useState(false);

  const { data: order, isLoading: isLoadingOrders } = useOrder(id);

  const { data: offer, isLoading: isLoadingOffers } = useUserOffer(
    id,
    user?.id
  );

  const { data: chat, isLoading: isLoadingChat } = useOrderChat(id);

  const { mutate: mutateChat, isPending: isLoadingCreateChat } = useCreateChat([
    queryKeys.CHAT,
  ]);

  const { mutate: mutateMessage, isPending: isLoadingMessage } =
    useCreateMessage([queryKeys.CHAT]);

  const { mutate: mutateOffer, isPending: isSendingOffer } = useCreateOffer(
    (res: any) => {
      onOpenNotification(t("tenders.seller.offerSent"));
      return navigate(`/offer/${res.id}`);
    },
    () => onOpenNotification(t("newMaterialForm.error"))
  );

  const onSubmit = (data: any) => {
    mutateOffer({
      ...data,
      order: { id: order?.id },
      user: { id: user?.id },
      oldPrice: parseFloat(data?.price),
      price: parseFloat(data?.price),
    });
    setOpen(false);
  };

  return (
    <Fragment>
      <Modal
        open={open}
        setOpen={setOpen}
        icon={
          <CheckCircleIcon
            className="h-6 w-6 text-green-600"
            aria-hidden="true"
          />
        }
        title={t("tenders.seller.offer")}
        message={t("tenders.seller.question")}
        onClickSuccess={onSubmit}
        onClickCancel={() => setOpen(false)}
      />
      {isLoadingOrders || isLoadingOffers || isSendingOffer ? (
        <Loader isFullScreen />
      ) : (
        <div className="min-h-full flex">
          <div className="flex flex-col w-0 flex-1">
            <div className="py-8 xl:py-10 relative">
              <div className="flex justify-between items-center pr-4 lg:pr-8">
                <GoBackArrow stylesAdded={"px-8"} />
                <button
                  onClick={() => setOpenChat(true)}
                  className="uppercase flex items-center gap-2 px-4 py-2 shadow-sm text-xs font-medium rounded border border-black bg-white focus:outline-none focus:ring-2 focus:ring-offset-2"
                >
                  <ChatBubbleOvalLeftEllipsisIcon className="h-4 w-4" />
                  {t("chat.title")}
                  {chat?.messages?.length > 0 && (
                    <span className="text-white border border-blue-600 bg-blue-600 px-1 rounded-full">
                      {chat?.messages?.length}
                    </span>
                  )}
                </button>
              </div>
              <div className="mx-auto px-4 lg:px-8">
                <BidHeader
                  order={order}
                  orderName={order?.name}
                  selected={offer?.selected}
                />
                {order && (
                  <OrderSummary
                    order={order}
                    type={order?.type}
                    currency={order?.currency}
                    measurementUnit={order?.measurementUnit}
                    onCreateOffer={mutateOffer}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <Slide open={openChat} setOpen={setOpenChat}>
        {order && (
          <Chat
            isOrder
            chat={chat}
            order={order}
            isLoadingChat={
              isLoadingChat || isLoadingCreateChat || isLoadingMessage
            }
            onCreateChat={mutateChat}
            onCreateMessage={mutateMessage}
          />
        )}
      </Slide>
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(IsSellerBid);
