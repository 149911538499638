import { connect } from "react-redux";
import IsBuyerOffer from "./IsBuyerOffer";
import IsBuyerMultipleOffer from "./IsBuyerMultipleOffer";
import { MaterialOfferResponse, ORDER_TYPES } from "../../types";
import { mapStateToProps } from "../../utils";
import IsMaterialOffer from "./IsMaterialOffer";

type OfferProps = {
  isBuy: boolean;
  type?: ORDER_TYPES;
  offer: any;
  materialOffer?: MaterialOfferResponse;
};

const Offer = ({ isBuy, type, offer, materialOffer }: OfferProps) => {
  return isBuy ? (
    type === ORDER_TYPES.JOINT || type === ORDER_TYPES.INVERSE_JOINT ? (
      <IsBuyerMultipleOffer offers={offer} />
    ) : (
      <IsBuyerOffer offer={offer} />
    )
  ) : (
    <>
      <IsMaterialOffer materialOffer={materialOffer} />
    </>
  );
};

export default connect(mapStateToProps)(Offer);
