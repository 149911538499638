import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { UserIcon } from "@heroicons/react/24/outline";
import { Dropdown, Loader } from "..";

import { mapDispatchToProps, mapStateToProps } from "../../utils";
import { CompanyResponse, userCompanyTypeMap, UserResponse } from "../../types";

type UserMobileMenuProps = {
  user: UserResponse;
  isBuy: boolean;
  isAdmin: boolean;
  isBuyer: boolean;
  isManager: boolean;
  isAnalyst: boolean;
  isRequester: boolean;
  isVisualizer: boolean;
  isCommercial: boolean;
  onLogout: () => void;
  classNames: (str: string, _str: string) => string;
  isCompanyList: CompanyResponse[];
  onChangeCompany: (company: string) => void;
  isChangingCompany: boolean;
  changeCompanyStatus: {
    status: number | null;
  };
};

const UserMobileMenu = ({
  user,
  isBuy,
  isRequester,
  onLogout,
  classNames,
  isCompanyList,
  onChangeCompany,
  isChangingCompany,
  changeCompanyStatus,
}: UserMobileMenuProps) => {
  const { t } = useTranslation();

  const handleCompanyChange = (company: CompanyResponse) => {
    if (company.id !== user.company.id) {
      onChangeCompany(company.id);
    }
  };

  if (changeCompanyStatus) {
    if (changeCompanyStatus.status === 200) {
      window.location.reload();
    }
    changeCompanyStatus.status = null;
  }

  return (
    <>
      {isChangingCompany && (
        <div className="absolute w-full bg-white z-50">
          <Loader isFullScreen />
        </div>
      )}
      <div className="flex-1 px-2 flex justify-end">
        <div className="border-gray-200 p-4 flex items-center sm:justify-end sm:px-6 lg:px-8">
          <div className="ml-4 flex items-center md:ml-6">
            <div className="ml-4 flex items-center md:ml-6">
              {isCompanyList ? (
                <Dropdown
                  currentCompany={user?.company?.name}
                  options={isCompanyList}
                  onClick={handleCompanyChange}
                />
              ) : (
                <div className="rounded bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300">
                  {user?.company?.name}
                </div>
              )}
              <Menu as="div" className="ml-3 relative">
                <div>
                  <Menu.Button className="bg-white rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light lg:p-2 lg:rounded lg:hover:bg-gray-50">
                    {user?.imageUrl ? (
                      <img
                        className="h-8 w-8 rounded-full"
                        src={user?.imageUrl}
                        alt={`${user?.fullName} - ${user?.company?.name}`}
                      />
                    ) : (
                      <UserIcon
                        className="h-8 w-8 rounded-full text-spectum"
                        fill="currentColor"
                      />
                    )}

                    <span className="hidden lg:flex lg:items-center">
                      <span
                        className="pl-1 text-sm font-semibold leading-6 text-gray-900"
                        aria-hidden="true"
                      >
                        {user?.fullName}
                        {user?.userCompanyType && (
                          <span className="text-gray-600">
                            {" "}
                            - (
                            {
                              userCompanyTypeMap[
                                user?.userCompanyType as keyof typeof userCompanyTypeMap
                              ]
                            }
                            )
                          </span>
                        )}
                      </span>
                      <ChevronDownIcon
                        className="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block"
                        aria-hidden="true"
                      />
                    </span>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to="/profile"
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700"
                          )}
                        >
                          {t("profile.title")}
                        </Link>
                      )}
                    </Menu.Item>
                    {isBuy && !isRequester ? (
                      <>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/sites"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              {t("profile.sites")}
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/delegate"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              {t("profile.delegate")}
                            </Link>
                          )}
                        </Menu.Item>
                      </>
                    ) : null}
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to="/"
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700"
                          )}
                          onClick={onLogout}
                        >
                          {t("profile.signOff")}
                        </Link>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserMobileMenu);
