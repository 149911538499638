import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import { GoBackArrow, Loader, InfoItem } from "../../components";
import IsSellerOffer from "../../components/Offer/IsMaterialOffer";
import { useID } from "../../hooks";
import {
  IT_DOESNT_HAVE_SPEC,
  NO_SPEC,
  numberFormatter,
  TO_BE_AGREED,
  IS_CONFIDENTIAL,
  addressFormatter,
} from "../../utils";
import { useSeller } from "../../hooks/useValidateSeller";
import { useMaterialOffer } from "../../hooks/useMaterialsOffers";

const MaterialOffer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const id = useID();

  const { data: seller } = useSeller();

  const {
    data: materialOffer,
    isLoading: isLoadingMaterialOffer,
    isError,
  } = useMaterialOffer(id);

  const sellerFound = seller?.find(
    (sel) => sel.material.id === materialOffer?.material?.id
  );

  const isSpec = NO_SPEC === materialOffer?.material?.fileURL;

  if (isError) navigate("/not-found");

  if (isLoadingMaterialOffer) return <Loader isFullScreen />;

  return (
    <Fragment>
      <div className="min-h-full flex">
        <div className="flex flex-col w-0 flex-1">
          <div className="py-8 xl:py-10">
            <GoBackArrow stylesAdded={"px-8"} />
            <div className="mx-auto px-4 lg:px-8 grid grid-cols-2">
              <div className="col-span-2 lg:col-span-1">
                <div>
                  <div>
                    <div className="md:flex md:items-center md:justify-between md:space-x-4 xl:border-b xl:pb-6">
                      <h1 className="text-2xl font-bold text-gray-900 uppercase">
                        {materialOffer?.material?.globalMaterialName}{" "}
                        <span className="text-sm text-gray-500">
                          {materialOffer?.material?.ean}
                        </span>
                      </h1>
                    </div>
                    {sellerFound?.validated && (
                      <>
                        <InfoItem
                          name={t("tenders.form.company")}
                          description={materialOffer?.material?.company?.name}
                        />
                        <InfoItem
                          name={t("tenders.form.fullName")}
                          description={materialOffer?.material?.owner?.fullName}
                        />
                        <InfoItem
                          name={t("tenders.form.email")}
                          description={materialOffer?.material?.owner?.email}
                        />
                        <InfoItem
                          name={t("tenders.form.phone")}
                          description={materialOffer?.material?.owner?.phone}
                        />
                      </>
                    )}
                    <InfoItem
                      name={
                        materialOffer
                          ? t("newMaterialForm.annualVolume")
                          : t("newMaterialForm.volume")
                      }
                      description={`${numberFormatter.format(
                        materialOffer?.material?.annualVolume as number
                      )} ${materialOffer?.material?.measurementUnit}`}
                    />
                    {materialOffer?.material?.coin && (
                      <InfoItem
                        name={t("newMaterialForm.currency")}
                        description={materialOffer?.material?.coin}
                      />
                    )}
                    {materialOffer?.material?.incoTerm && (
                      <InfoItem
                        name={t("newMaterialForm.incoTerm")}
                        description={materialOffer?.material?.incoTerm}
                      />
                    )}
                    {materialOffer?.material?.certifications &&
                      materialOffer?.material?.certifications?.length > 0 && (
                        <InfoItem
                          name={t("certifications.certifications")}
                          description={materialOffer?.material?.certifications
                            ?.map((cert) => cert.name)
                            .join(", ")}
                        />
                      )}
                    {materialOffer?.material?.deliveryFormat && (
                      <InfoItem
                        name={t("newMaterialForm.deliveryFormat")}
                        description={materialOffer?.material?.deliveryFormat}
                      />
                    )}
                    <InfoItem
                      name={t("newMaterialForm.deliveryPlace")}
                      description={
                        addressFormatter(
                          materialOffer?.material?.deliveryPlace
                            ?.address as string
                        ) ?? TO_BE_AGREED
                      }
                    />
                    {materialOffer?.material?.comments && (
                      <InfoItem
                        name={t("newMaterialForm.comments")}
                        description={materialOffer?.material?.comments}
                      />
                    )}
                    {materialOffer?.material?.application && (
                      <InfoItem
                        name={t("newMaterialForm.application")}
                        description={materialOffer?.material?.application}
                      />
                    )}
                    <InfoItem
                      name={t("newMaterialForm.specification")}
                      description={
                        <Fragment>
                          {materialOffer?.material?.confidentSpec &&
                          !sellerFound?.validated ? (
                            <p>{IS_CONFIDENTIAL}</p>
                          ) : isSpec ? (
                            IT_DOESNT_HAVE_SPEC
                          ) : (
                            <a
                              href={materialOffer?.material?.fileURL}
                              target="_blank"
                              rel="noreferrer"
                              className="inline-flex items-center px-2 py-1 border border-transparent shadow-xs text-xs leading-4 font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum"
                            >
                              <ArrowDownTrayIcon
                                className="-ml-0.5 mr-2 h-4 w-4"
                                aria-hidden="true"
                              />
                              Descargar Spec.
                            </a>
                          )}
                        </Fragment>
                      }
                    />
                  </div>
                </div>
              </div>
              {materialOffer && (
                <IsSellerOffer
                  materialOffer={materialOffer}
                  valid={sellerFound?.validated}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MaterialOffer;
