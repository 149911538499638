import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "../axios-orders";
import { OrderState } from "../types";
import { queryKeys } from "./queryKeys";

const onGetUserPurchaseRequestsByState = async (
  state: OrderState,
  page = 0
) => {
  const { data } = await axios.get(`/purchase-request/state/${state}/${page}`);
  return data;
};

export const useUserPurchaseRequestsByState = (
  selectedTab: number,
  isRequester: boolean
) =>
  useQuery({
    queryKey: [queryKeys.USER_PURCHASE_REQUESTS_BY_STATE, selectedTab],
    queryFn: () => {
      let state = OrderState.DELIVERED;
      if (selectedTab === 1) {
        state = OrderState.DRAFT;
      } else if (selectedTab === 2) {
        state = OrderState.OPEN;
      } else if (selectedTab === 3) {
        state = OrderState.REJECTED;
      } else if (selectedTab === 4) {
        state = OrderState.SUCCEED;
      }
      return onGetUserPurchaseRequestsByState(state);
    },
    enabled: isRequester,
  });

const onGetUserPurchaseRequests = async (page = 0) => {
  const { data } = await axios.get(`/purchase-request/user/${page}`);
  return data;
};

export const useUserPurchaseRequests = (page: number, isRequester?: boolean) =>
  useQuery({
    queryKey: [queryKeys.USER_PURCHASE_REQUESTS, page],
    queryFn: () => onGetUserPurchaseRequests(page),
    enabled: !isRequester,
  });

const onGetMultiplePurchaseRequests = async (page = 0) => {
  const { data } = await axios.get(`/purchase-request/user/multiple/${page}`);
  return data;
};

export const useMultiplePurchaseRequests = (
  page: number,
  isRequester?: boolean
) =>
  useQuery({
    queryKey: [queryKeys.MULTIPLE_PURCHASE_REQUESTS, page],
    queryFn: () => onGetMultiplePurchaseRequests(page),
    enabled: !isRequester,
  });

const onGetMultiplePurchaseRequestsByState = async (state: any, page = 0) => {
  const { data } = await axios.get(
    `/purchase-request/user/multiple/${state}/${page}`
  );
  return data;
};

export const useMultiplePurchaseRequestsByState = (
  selectedTab: number,
  isRequester: boolean
) =>
  useQuery({
    queryKey: [queryKeys.MULTIPLE_PURCHASE_REQUESTS_BY_STATE, selectedTab],
    queryFn: () => {
      let state = OrderState.DELIVERED;
      if (selectedTab === 1) {
        state = OrderState.DRAFT;
      } else if (selectedTab === 2) {
        state = OrderState.OPEN;
      } else if (selectedTab === 3) {
        state = OrderState.REJECTED;
      } else if (selectedTab === 4) {
        state = OrderState.SUCCEED;
      }
      return onGetMultiplePurchaseRequestsByState(state);
    },
    enabled: isRequester,
  });

const onGetPurchaseRequest = async (purchaseRequestId: string) => {
  const { data } = await axios.get(`/purchase-request/${purchaseRequestId}`);
  return data;
};

export const usePurchaseRequest = (purchaseRequestId: string) =>
  useQuery({
    queryKey: [queryKeys.PURCHASE_REQUEST, purchaseRequestId],
    queryFn: () => onGetPurchaseRequest(purchaseRequestId || ""),
    enabled: !!purchaseRequestId,
  });

const onGetMultiplePurchaseRequest = async (
  url: string,
  multiplePurchaseRequestId: string
) => {
  const { data } = await axios.get(`${url}${multiplePurchaseRequestId}`);
  return data;
};

export const useMultiplePurchaseRequest = (
  url: string,
  multiplePurchaseRequestId: string
) =>
  useQuery({
    queryKey: [queryKeys.MULTIPLE_PURCHASE_REQUEST, multiplePurchaseRequestId],
    queryFn: () =>
      onGetMultiplePurchaseRequest(url, multiplePurchaseRequestId || ""),
    enabled: !!multiplePurchaseRequestId,
  });

const onCreateMultiplePurchaseRequest = async (body: any) => {
  const { data } = await axios.post(`/order/multiple/purchase-request`, body);
  return data;
};

export const useCreateMultiplePurchaseRequest = (
  onSuccess: () => void,
  onError: () => void
) =>
  useMutation({
    mutationFn: onCreateMultiplePurchaseRequest,
    onSuccess,
    onError,
  });

const onCreatePurchaseRequest = async (body: any) => {
  const { data } = await axios.post(`/order/purchase-request`, body);
  return data;
};

export const useCreatePurchaseRequest = (
  onSuccess: () => void,
  onError: () => void
) =>
  useMutation({
    mutationFn: onCreatePurchaseRequest,
    onSuccess,
    onError,
  });

const onDeletePurchaseRequest = async (purchaseRequestId: string) => {
  const { data } = await axios.delete(`/purchase-request/${purchaseRequestId}`);
  return data;
};

export const useDeletePurchaseRequest = (
  onSuccess: () => void,
  onError: () => void
) =>
  useMutation({
    mutationFn: onDeletePurchaseRequest,
    onSuccess,
    onError,
  });

const onUpdatePurchaseRequest = async (body: any) => {
  const { data } = await axios.put(`/order/list`, body);
  return data;
};

export const useUpdatePurchaseRequest = (
  onSuccess: (res: any) => void,
  onError: () => void
) =>
  useMutation({
    mutationFn: onUpdatePurchaseRequest,
    onSuccess,
    onError,
  });

const onGetCountPurchaseRequests = async () => {
  const { data } = await axios.get(`/purchase-request/count`);
  return data;
};

export const useCountPurchaseRequests = (isRequester: boolean) =>
  useQuery({
    queryKey: [queryKeys.COUNT_PURCHASE_REQUESTS],
    queryFn: () => onGetCountPurchaseRequests(),
    enabled: isRequester,
  });

const onRejectPurchaseRequest = async ({
  purchaseRequestId,
  body,
}: {
  purchaseRequestId: string;
  body: { comment: string };
}) => {
  const { data } = await axios.post(
    `/purchase-request/reject/${purchaseRequestId}`,
    body
  );
  return data;
};

export const useRejectPurchaseRequest = (
  onSuccess: (res: any) => void,
  onError: () => void
) =>
  useMutation({
    mutationFn: onRejectPurchaseRequest,
    onSuccess,
    onError,
  });
