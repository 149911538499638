import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/outline";
import { mapDispatchToProps } from "../../utils";
import {
  GoBackArrow,
  Loader,
  JointOrderSummary,
  Slide,
  Chat,
} from "../../components";
import { useID } from "../../hooks";
import BidHeader from "./BidHeader";
import { useJointOrder } from "../../hooks/useOrders";
import {
  useCreateChat,
  useCreateMessage,
  useMultipleOrderChat,
} from "../../hooks/useChat";
import {
  useCreateMultipleOffer,
  useOffersOnOrders,
} from "../../hooks/useOffers";
import { queryKeys } from "../../hooks/queryKeys";

const IsJointSellerBid = ({
  onOpenNotification,
}: {
  onOpenNotification: (message: string) => void;
}) => {
  const { t } = useTranslation();
  const id = useID();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [openChat, setOpenChat] = useState(false);

  const multipleOrderId = searchParams.get("multipleOrderId");

  const { data: multipleOrderChat, isLoading: isLoadingChat } =
    useMultipleOrderChat(multipleOrderId || "");

  const { mutate: mutateChat, isPending: isLoadingCreateChat } = useCreateChat([
    queryKeys.MULTIPLE_ORDER_CHAT,
  ]);

  const { mutate: mutateMessage, isPending: isLoadingMessage } =
    useCreateMessage([queryKeys.MULTIPLE_ORDER_CHAT]);

  const { data: jointOrder, isLoading: isLoadingOrders } = useJointOrder(id);

  const { data: offersOnOrders, isLoading: isLoadingOffers } =
    useOffersOnOrders(id);

  const { mutate: createMultipleOffer, isPending: isCreatingMultipleOffer } =
    useCreateMultipleOffer((res) => {
      onOpenNotification(t("tenders.seller.offerSent"));
      return navigate(
        `/offer/multiple/${res[0]?.order?.id}?multipleOrderId=${multipleOrderId}`
      );
    });

  const isTendersSucceded = offersOnOrders?.content?.find(
    (el: any) => el.selected
  );

  return (
    <Fragment>
      {isLoadingOrders || isLoadingOffers || isCreatingMultipleOffer ? (
        <Loader isFullScreen />
      ) : (
        <div className="min-h-full flex">
          <div className="flex flex-col w-0 flex-1">
            <div className="py-8 xl:py-10 relative">
              <div className="w-full px-8 flex justify-between items-center gap-4">
                <GoBackArrow />
                <button
                  onClick={() => setOpenChat(true)}
                  className="uppercase flex items-center gap-2 px-4 py-2 shadow-sm text-xs font-medium rounded border border-black bg-white focus:outline-none focus:ring-2 focus:ring-offset-2"
                >
                  <ChatBubbleOvalLeftEllipsisIcon className="h-4 w-4" />
                  {t("chat.title")}
                  {multipleOrderChat?.messages?.length > 0 && (
                    <span className="text-white border border-blue-600 bg-blue-600 px-1 rounded-full">
                      {multipleOrderChat?.messages?.length}
                    </span>
                  )}
                </button>
              </div>
              <div className="mx-auto px-4 lg:px-8 gap-1">
                <div>
                  <BidHeader
                    order={jointOrder[0]}
                    orderName={jointOrder[0]?.name}
                    selected={isTendersSucceded?.selected}
                  />
                  <JointOrderSummary
                    orderSummary={jointOrder}
                    type={jointOrder[0]?.type}
                    currency={jointOrder[0]?.currency}
                    measurementUnit={jointOrder[0]?.measurementUnit}
                    onCreateMultipleOffer={createMultipleOffer}
                  />
                  <div className="mt-4 p-2 rounded bg-gray-200">
                    <p className="font-bold text-xxs text-center uppercase">
                      {t("tenders.disclaimer")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Slide open={openChat} setOpen={setOpenChat}>
        {jointOrder && (
          <Chat
            isOrder
            isMultiple
            chat={multipleOrderChat}
            order={jointOrder[0]}
            onCreateChat={mutateChat}
            isLoadingChat={
              isLoadingChat || isLoadingCreateChat || isLoadingMessage
            }
            onCreateMessage={mutateMessage}
          />
        )}
      </Slide>
    </Fragment>
  );
};

export default connect(null, mapDispatchToProps)(IsJointSellerBid);
