import { useEffect, useState } from "react";
import i18next from "../../i18n";
import {
  Loader,
  UserMaterialOpportunitiesTable,
  CategoriesBuilder,
  UserMaterialOpportunityBox,
} from "../../components";
import { MaterialType } from "../../types";
import { useUserMaterialCategories } from "../../hooks/useUserMaterialCategories";
import { useAvailableCountries } from "../../hooks/useCategories";
import {
  useOpportunitiesByLastId,
  useSearchOpportunitiesByLastIds,
} from "../../hooks/useMaterials";
import { useMaterialOpportunitiesByFamily } from "../../hooks/useMaterialOpportunities";

const Opportunities = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [pnType, setPnType] = useState<MaterialType>(MaterialType.PI);

  const [materialOpportunitiesByFamily, setMaterialOpportunitiesByFamily] =
    useState<any>(null);
  const [opportunitiesByLastIds, setOpportunitiesByLastIds] =
    useState<any>(null);

  const [pageNumber, setPageNumber] = useState(0);
  const [familyPage, setFamilyPage] = useState(0);
  const [searchPage, setSearchPage] = useState(0);

  const availableCountries = useAvailableCountries();

  const userMaterialCategories = useUserMaterialCategories();

  const {
    mutate: getMaterialOpportunitiesByFamily,
    isPending: isGettingOpportunities,
  } = useMaterialOpportunitiesByFamily((res) => {
    setOpportunitiesByLastIds(null);
    setMaterialOpportunitiesByFamily(res);
  });

  const {
    mutate: getOpportunitiesByLastIds,
    isPending: isLoadingOpportunities,
  } = useOpportunitiesByLastId((res) => {
    setMaterialOpportunitiesByFamily(null);
    setOpportunitiesByLastIds(res);
  });

  const {
    data: foundOpportunitiesByLastIds,
    mutate: searchOpportunitiesByLastIds,
    isPending: isSearching,
  } = useSearchOpportunitiesByLastIds();

  useEffect(() => {
    if (userMaterialCategories?.data?.length > 0) {
      const userPnType =
        userMaterialCategories.data[0]?.category?.parent?.parent?.parent?.name;
      setPnType(userPnType);
    }
  }, []);

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchTerm(e.target.value);

  return (
    <div className="px-4 mt-6">
      <h2 className="text-xl font-bold leading-7 text-gray-900 sm:truncate uppercase">
        {i18next.t("opportunities.title")}
      </h2>
      <p className="py-3 text-sm">{i18next.t("opportunities.subtitle")}</p>

      {userMaterialCategories?.data && (
        <CategoriesBuilder
          pnType={pnType}
          setPnType={setPnType}
          pageNumber={pageNumber}
          searchPage={searchPage}
          searchTerm={searchTerm}
          familyPage={familyPage}
          onChangeSearch={onChangeSearch}
          availableCountries={availableCountries?.data}
          userMaterialCategories={userMaterialCategories?.data}
          onGetOpportunitiesByLastIds={getOpportunitiesByLastIds}
          onSearchOpportunitiesByLastIds={searchOpportunitiesByLastIds}
          onGetMaterialOpportunitiesByFamily={getMaterialOpportunitiesByFamily}
        />
      )}

      {isGettingOpportunities || isLoadingOpportunities || isSearching ? (
        <div className="py-2">
          <Loader />
        </div>
      ) : (
        <>
          {searchTerm === "" && opportunitiesByLastIds?.content?.length > 0 ? (
            <UserMaterialOpportunitiesTable
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              opportunitiesByLastIds={opportunitiesByLastIds}
            />
          ) : null}

          {searchTerm !== "" &&
            foundOpportunitiesByLastIds?.content?.length > 0 && (
              <UserMaterialOpportunitiesTable
                pageNumber={searchPage}
                setPageNumber={setSearchPage}
                foundOpportunitiesByLastIds={foundOpportunitiesByLastIds}
              />
            )}

          {(opportunitiesByLastIds?.content?.length === 0 ||
            !opportunitiesByLastIds) &&
            (materialOpportunitiesByFamily?.content?.length === 0 ||
              !materialOpportunitiesByFamily) && (
              <h4 className="text-xs leading-7 text-gray-900 sm:truncate pt-4">
                {i18next.t("opportunities.noOpportunities")}
              </h4>
            )}

          {materialOpportunitiesByFamily?.content?.length > 0 && (
            <>
              {!materialOpportunitiesByFamily?.content[0]?.siblings?.every(
                (item: any) => item.materials.length === 0
              ) && (
                <UserMaterialOpportunityBox
                  materialOpportunitiesByFamily={materialOpportunitiesByFamily}
                  setFamilyPage={setFamilyPage}
                  familyPage={familyPage}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Opportunities;
