import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/outline";
import {
  GoBackArrow,
  Loader,
  TenderSucceeded,
  Slide,
  Chat,
  OfferSummary,
} from "../../components";
import { useID } from "../../hooks";
import {
  useCreateChat,
  useCreateMessage,
  useOrderChat,
} from "../../hooks/useChat";
import { useOffer, useUpdateInverseOffer } from "../../hooks/useOffers";
import { queryKeys } from "../../hooks/queryKeys";
import BidHeader from "./BidHeader";
import BidResultTable from "./BidResultTable";
import { mapDispatchToProps } from "../../utils";
import { useQueryClient } from "@tanstack/react-query";

const IsSellerOffer = ({
  onOpenNotification,
}: {
  onOpenNotification: (message: string) => void;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const id = useID();
  const [openChat, setOpenChat] = useState(false);

  const queryClient = useQueryClient();

  const { data: offer, isLoading: isLoadingOffers, isError } = useOffer(id);

  const { data: chat, isLoading: isLoadingChat } = useOrderChat(
    offer?.order?.id || ""
  );

  const { mutate: mutateChat, isPending: isLoadingCreateChat } = useCreateChat([
    queryKeys.CHAT,
  ]);

  const { mutate: mutateMessage, isPending: isLoadingMessage } =
    useCreateMessage([queryKeys.CHAT]);

  const { mutate: updateInverseOffer, isPending: isUpdatingInverseOffer } =
    useUpdateInverseOffer(
      () => {
        queryClient.invalidateQueries({
          queryKey: [queryKeys.OFFER, id],
        });
        onOpenNotification(t("tenders.seller.modifiedOffer"));
      },
      () => onOpenNotification(t("newMaterialForm.error"))
    );

  if (isLoadingOffers || isUpdatingInverseOffer) return <Loader isFullScreen />;

  if (isError) navigate("/not-found");

  return (
    <Fragment>
      <div className="min-h-full flex">
        <div className="flex flex-col w-0 flex-1">
          <div className="py-8 xl:py-10">
            <div className="flex justify-between items-center mb-4 pr-4 lg:pr-8">
              <GoBackArrow stylesAdded={"px-8"} isOffer />
              <button
                onClick={() => setOpenChat(true)}
                className="uppercase flex items-center gap-2 px-4 py-2 shadow-sm text-xs font-medium rounded border border-black bg-white focus:outline-none focus:ring-2 focus:ring-offset-2"
              >
                <ChatBubbleOvalLeftEllipsisIcon className="h-4 w-4" />
                {t("chat.title")}
                {chat?.messages?.length > 0 && (
                  <span className="text-white border border-blue-600 bg-blue-600 px-1 rounded-full">
                    {chat?.messages?.length}
                  </span>
                )}
              </button>
            </div>
            <div className="mx-auto px-4 lg:px-8">
              <BidHeader
                order={offer}
                orderName={offer?.order?.name}
                selected={offer?.selected}
              />

              {offer?.selected && (
                <>
                  <BidResultTable
                    offer={offer}
                    title={t("tenders.seller.accepted")}
                  />
                  <TenderSucceeded tender={offer?.order} />
                  <hr />
                </>
              )}

              {offer && (
                <>
                  <OfferSummary
                    offer={offer}
                    onUpdateOffer={updateInverseOffer}
                  />
                  <div className="mt-4 p-2 rounded bg-gray-200">
                    <p className="font-bold text-xxs text-center uppercase">
                      {t("tenders.disclaimer")}
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Slide open={openChat} setOpen={setOpenChat}>
        {offer && (
          <Chat
            isOrder
            chat={chat}
            order={offer?.order}
            onCreateChat={mutateChat}
            isLoadingChat={
              isLoadingChat || isLoadingMessage || isLoadingCreateChat
            }
            onCreateMessage={mutateMessage}
          />
        )}
      </Slide>
    </Fragment>
  );
};

export default connect(null, mapDispatchToProps)(IsSellerOffer);
