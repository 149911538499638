import { useEffect, useReducer, useState } from "react";
import i18next from "../../i18n";
import { useDebounce } from "../../hooks";
import { MultiPickList, Search } from "..";
import {
  actions,
  reducer,
  extractBuyTeamCategories,
  extractServiceClassCategories,
  extractServiceFamilyCategories,
} from "./utils";
import {
  CountryResponse,
  MaterialType,
  UserMaterialCategory,
} from "../../types";
import {
  MaterialOpportunitiesByLastIdType,
  SearchMaterialOpportunitiesByLastIdType,
} from "../../hooks/useMaterials";
import {
  useCategoriesByParent,
  useCategories,
} from "../../hooks/useCategories";
import { queryKeys } from "../../hooks/queryKeys";

type CategoriesBuilderProps = {
  pnType: MaterialType;
  setPnType: (value: MaterialType) => void;
  availableCountries: any;
  userMaterialCategories: UserMaterialCategory[];
  onGetOpportunitiesByLastIds: ({
    countryIds,
    page,
    type,
    categoryIds,
  }: MaterialOpportunitiesByLastIdType) => void;
  onGetMaterialOpportunitiesByFamily: ({
    page,
    countryIds,
    categoryIds,
  }: {
    page: number;
    countryIds: string[];
    categoryIds: string[];
  }) => void;
  onSearchOpportunitiesByLastIds: ({
    query,
    page,
    type,
    categoryIds,
    countryIds,
  }: SearchMaterialOpportunitiesByLastIdType) => void;
  searchPage: number;
  pageNumber: number;
  familyPage: number;
  searchTerm: string;
  onChangeSearch: (value: React.ChangeEvent<HTMLInputElement>) => void;
};

const CategoriesBuilder = ({
  pnType,
  setPnType,
  availableCountries,
  userMaterialCategories,
  onGetOpportunitiesByLastIds,
  onGetMaterialOpportunitiesByFamily,
  onSearchOpportunitiesByLastIds,
  searchPage,
  pageNumber,
  familyPage,
  searchTerm,
  onChangeSearch,
}: CategoriesBuilderProps) => {
  const [disabled, setDisabled] = useState(true);
  const { debouncedSearchTerm } = useDebounce(searchTerm, 800);

  const { data: buyTeams } = useCategoriesByParent(queryKeys.BUY_TEAMS, pnType);

  const initialBuyTeamCategories = userMaterialCategories?.reduce(
    (acc: any[], cat: UserMaterialCategory) => {
      const buyTeams = extractBuyTeamCategories(cat.category);
      return [...acc, ...buyTeams];
    },
    []
  );

  const uniqueBuyTeamCategories = Array.from(
    new Set(initialBuyTeamCategories?.map((item) => JSON.stringify(item)))
  )?.map((item) => JSON.parse(item));

  const initialServiceFamilyCategories = userMaterialCategories?.reduce(
    (acc: any[], cat: UserMaterialCategory) => {
      const productFamilies = extractServiceFamilyCategories(cat.category);
      return [...acc, ...productFamilies];
    },
    []
  );

  const uniqueServiceFamilyCategories = Array.from(
    new Set(initialServiceFamilyCategories?.map((item) => JSON.stringify(item)))
  )?.map((item) => JSON.parse(item));

  const initialServiceClassCategories = userMaterialCategories?.reduce(
    (acc: any[], cat: UserMaterialCategory) => {
      const productFamilies = extractServiceClassCategories(cat.category);
      return [...acc, ...productFamilies];
    },
    []
  );

  const uniqueServiceClassCategories = Array.from(
    new Set(initialServiceClassCategories?.map((item) => JSON.stringify(item)))
  )?.map((item) => JSON.parse(item));

  const initialCountries = userMaterialCategories?.length > 0 && [
    userMaterialCategories[0]?.user?.company?.country,
  ];

  const getInitialCategorySelections = () => {
    return {
      countries: initialCountries || [],
      buyTeamCategories: uniqueBuyTeamCategories || [],
      serviceFamilyCategories: uniqueServiceFamilyCategories || [],
      serviceClassCategories: uniqueServiceClassCategories || [],
    };
  };

  const [event, updateEvent] = useReducer(
    reducer,
    undefined,
    getInitialCategorySelections
  );

  const { data: productServiceFamily } = useCategories(
    queryKeys.PRODUCT_SERVICE_FAMILY,
    event.buyTeamCategories
  );

  const { data: productServiceClass } = useCategories(
    queryKeys.PRODUCT_SERVICE_CLASS,
    event.serviceFamilyCategories
  );

  useEffect(() => {
    if (debouncedSearchTerm && searchTerm.length >= 3) {
      const serviceClassCategoriesIds = event.serviceClassCategories.map(
        (item: any) => item.id
      );
      const countryIds = event.countries.map((item: any) => item.id);
      onSearchOpportunitiesByLastIds({
        query: searchTerm,
        page: searchPage,
        type: pnType,
        categoryIds: serviceClassCategoriesIds,
        countryIds,
      });
    }
  }, [debouncedSearchTerm, searchTerm]);

  useEffect(() => {
    if (productServiceClass?.length > 0) {
      updateEvent({
        type: actions.SELECT_ALL_SERVICE_CLASS_CATEGORIES,
        items: productServiceClass,
      });
    }
  }, [productServiceClass]);

  useEffect(() => {
    const countryIds = event.countries.map((item) => item.id);
    const serviceClassCategoriesIds = event.serviceClassCategories.map(
      (item: any) => item.id
    );
    if (pnType === MaterialType.PI) {
      onGetOpportunitiesByLastIds({
        countryIds,
        page: pageNumber,
        type: pnType,
        categoryIds: serviceClassCategoriesIds,
      });
    } else {
      onGetMaterialOpportunitiesByFamily({
        page: familyPage,
        countryIds,
        categoryIds: serviceClassCategoriesIds,
      });
    }
  }, [pageNumber, familyPage]);

  useEffect(() => {
    if (userMaterialCategories?.length > 0) {
      const serviceClassCategoriesIds = event.serviceClassCategories.map(
        (item: any) => item.id
      );
      const countryIds = event.countries.map((item) => item.id);
      onGetOpportunitiesByLastIds({
        countryIds,
        page: pageNumber,
        type: pnType,
        categoryIds: serviceClassCategoriesIds,
      });
    }
  }, [pageNumber]);

  const handleFilters = (e: any) => {
    e.preventDefault();
    const serviceClassCategoriesIds = event.serviceClassCategories.map(
      (item: any) => item.id
    );

    localStorage.setItem("pnType", pnType);
    const countryIds = event.countries.map((item) => item.id);

    if (event.serviceClassCategories.length > 0 && pnType === MaterialType.PI) {
      onGetOpportunitiesByLastIds({
        countryIds,
        page: pageNumber,
        type: pnType,
        categoryIds: serviceClassCategoriesIds,
      });
    } else {
      onGetMaterialOpportunitiesByFamily({
        page: familyPage,
        countryIds,
        categoryIds: serviceClassCategoriesIds,
      });
    }

    setDisabled(true);
  };

  return (
    <form>
      <div className="grid grid-cols-5 gap-1 mb-1">
        <div className="col-span-1">
          <MultiPickList
            items={availableCountries?.sort(
              (a: CountryResponse, b: CountryResponse) =>
                a.name.localeCompare(b.name)
            )}
            setDisabled={setDisabled}
            label={i18next.t("material.country")}
            updateEvent={updateEvent}
            categories={event.countries}
            action={actions.TOGGLE_COUNTRY}
          />
        </div>
      </div>
      <div className="grid grid-cols-5 gap-1 mb-4">
        <select
          className="col-span-5 lg:col-span-1 border-gray-300 border flex items-center cursor-pointer uppercase font-semibold text-xs rounded w-full m-0"
          onChange={(e) => {
            setPnType(e.target.value as MaterialType);
            updateEvent({
              type: actions.CLEAR_SELECTION,
            });
          }}
          value={pnType}
        >
          <option value={MaterialType.PI}>
            {i18next.t("titles.productive")}
          </option>
          <option value={MaterialType.NPI}>
            {i18next.t("titles.nonProductive")}
          </option>
          <option value={MaterialType.SERVICE}>
            {i18next.t("titles.services")}
          </option>
        </select>
        {buyTeams?.length > 0 && (
          <MultiPickList
            setDisabled={setDisabled}
            items={buyTeams}
            label={i18next.t("material.buyTeam")}
            updateEvent={updateEvent}
            categories={event.buyTeamCategories}
            action={actions.TOGGLE_BUY_TEAM_CATEGORY}
          />
        )}
        {productServiceFamily?.length > 0 &&
          event.buyTeamCategories.length > 0 && (
            <MultiPickList
              setDisabled={setDisabled}
              updateEvent={updateEvent}
              items={productServiceFamily}
              label={i18next.t("material.productFamily")}
              categories={event.serviceFamilyCategories}
              action={actions.TOGGLE_SERVICE_FAMILY_CATEGORY}
            />
          )}
        {productServiceClass?.length > 0 &&
          event.serviceFamilyCategories.length > 0 && (
            <MultiPickList
              setDisabled={setDisabled}
              updateEvent={updateEvent}
              items={productServiceClass}
              label={i18next.t("material.productClass")}
              categories={event.serviceClassCategories}
              action={actions.TOGGLE_SERVICE_CLASS_CATEGORY}
            />
          )}
        <div className="col-span-5 md:col-span-2 lg:col-span-1">
          <button
            className={`${
              disabled
                ? "bg-gray-200 text-gray-600 cursor-not-allowed"
                : "bg-spectum hover:bg-spectum-light text-white"
            } w-full py-2 px-4 border border-transparent shadow-sm text-xs font-semibold rounded focus:outline-none focus:ring-2 focus:ring-offset-2 uppercase`}
            onClick={(e) => handleFilters(e)}
            disabled={disabled}
          >
            {i18next.t("cta.filter")}
          </button>
        </div>
      </div>
      {event.serviceClassCategories.length > 0 && (
        <Search value={searchTerm} handleSearch={onChangeSearch} border />
      )}
    </form>
  );
};

export default CategoriesBuilder;
