import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "../axios-orders";
import { InvitationResponse } from "../types";
import { queryKeys } from "./queryKeys";

const onInviteSupplier = async ({
  emails,
  materialId,
}: {
  emails: string;
  materialId: string;
}) => {
  const { data } = await axios.get(
    `/user/invitation/material/${emails}/${materialId}`
  );
  return data;
};

export const useInviteSupplier = (
  onSuccess: (res: any) => void,
  onError: () => void
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: onInviteSupplier,
    onSuccess: (res: any) => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.INVITED_MATERIAL_SUPPLIERS],
      });
      onSuccess(res);
    },
    onError,
  });
};

const onGetInvitedMaterialSuppliers = async (materialId: string) => {
  const { data } = await axios.get(`/user/invitations/material/${materialId}`);
  return data;
};

export const useInvitedMaterialSuppliers = (materialId: string) =>
  useQuery<InvitationResponse[]>({
    queryKey: [queryKeys.INVITED_MATERIAL_SUPPLIERS, materialId],
    queryFn: () => onGetInvitedMaterialSuppliers(materialId),
    enabled: !!materialId,
  });

const onGetInvitedOrderSuppliers = async (orderId: string) => {
  const { data } = await axios.get(`/user/invitations/order/${orderId}`);
  return data;
};

export const useInvitedOrderSuppliers = (orderId: string) =>
  useQuery({
    queryKey: [queryKeys.INVITED_ORDER_SUPPLIERS, orderId],
    queryFn: () => onGetInvitedOrderSuppliers(orderId),
  });

const onGetInvitedSuppliers = async (materialIds: string[]) => {
  const { data } = await axios.post(`/user/invitations/materials`, materialIds);
  return data;
};

export const useInvitedSuppliers = (
  onSuccess?: (res: InvitationResponse[]) => void,
  onError?: () => void
) =>
  useMutation({
    mutationFn: onGetInvitedSuppliers,
    onSuccess,
    onError,
  });

type PotentialSuppliersProps = {
  countryId: string;
  page: number;
  materialIds: string[];
};

const onGetPotentialSuppliers = async ({
  countryId,
  page,
  materialIds,
}: PotentialSuppliersProps) => {
  const { data } = await axios.post(
    `/user/material/id/opportunities/country/${countryId}/${page}`,
    materialIds
  );
  return data;
};

export const usePotentialSuppliers = () =>
  useMutation({
    mutationFn: onGetPotentialSuppliers,
  });
