import { Fragment, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import {
  PN_TYPE,
  SAMPLE_STATE,
  mapDispatchToProps,
  mapStateToProps,
} from "../../utils";
import { Chat, Loader, MaterialOffer, PoweredByAI, Slide, Tabs } from "..";
import Detail from "./Detail/Detail";
import Delegate from "./Delegate/Delegate";
import PricingVolume from "./PricingVolume/PricingVolume";
import { useID } from "../../hooks";
import FormattedText from "../UI/FormattedText/FormattedText";
import Suppliers from "./Suppliers/Suppliers";
import BiddingList from "./BiddingList/BiddingList";
import Actions from "./Actions/Actions";
import InviteSupplier from "./InviteSupplier/InviteSupplier";
import i18next from "../../i18n";
import PotencialSupplier from "./PotencialSupplier/PotencialSupplier";
import {
  CombinedBuyerSellerMaterialOfferResponse,
  MaterialResponse,
  UserResponse,
} from "../../types";
import { openNotification } from "../../store/actions/notificationActions";
import { useMaterialMarketInfo } from "../../hooks/useAI";
import { useCountriesByMaterialId } from "../../hooks/useCountries";
import { useChat, useCreateChat, useCreateMessage } from "../../hooks/useChat";
import {
  useToggleMaterialStatus,
  useUpdateMaterial,
} from "../../hooks/useMaterials";
import {
  useRejectSeller,
  useValidateSeller,
} from "../../hooks/useValidateSeller";
import {
  useCreateMaterialOfferInvitation,
  useMaterialOffers,
  useQualifySample,
  useRequestSample,
} from "../../hooks/useMaterialsOffers";
import { queryKeys } from "../../hooks/queryKeys";

const tabs = [
  { name: i18next.t("material.detail"), href: "#", current: true },
  { name: i18next.t("material.volumePrice"), href: "#", current: false },
  { name: i18next.t("material.tenders"), href: "#", current: false },
];

const npiTabs = [
  { name: i18next.t("material.detail"), href: "#", current: true },
  { name: i18next.t("material.tenders"), href: "#", current: false },
];

type MaterialProps = {
  user: UserResponse;
  material: MaterialResponse;
  onOpenNotification: (message: string) => void;
};

const Material = ({ user, onOpenNotification, material }: MaterialProps) => {
  const id = useID();
  const [selectedTab, setSelectedTab] = useState(0);
  const userId = new URLSearchParams(useLocation().search).get("userId");
  const navigate = useNavigate();
  const goToBidding = () =>
    navigate({
      pathname: "/order",
      search: `?id=${id}`,
    });

  const [open, setOpen] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const [openInvite, setOpenInvite] = useState(false);
  const [openDelegate, setOpenDelegate] = useState(false);
  const [openSupplier, setOpenSupplier] = useState(false);
  const [potencialSupplierToShow, setPotencialSupplierToShow] =
    useState<any>("");
  const [materialOfferToShow, setMaterialOfferToShow] =
    useState<CombinedBuyerSellerMaterialOfferResponse>();

  const { data: materialOffers, isLoading: isLoadingMaterialOffer } =
    useMaterialOffers(id, userId || "");

  const { data: materialMarketInfo, isLoading: isLoadingMaterialMarketInfo } =
    useMaterialMarketInfo(id, material?.type);

  const { data: countriesByMaterial } = useCountriesByMaterialId(
    id,
    material?.type
  );

  const { data: chat, isLoading: isLoadingChat } = useChat(
    materialOfferToShow?.materialOffer?.user?.id || "",
    material?.id,
    openChat
  );

  const { mutate: mutateChat, isPending: isLoadingCreateChat } = useCreateChat([
    queryKeys.CHAT,
  ]);

  const { mutate: mutateMessage, isPending: isLoadingMessage } =
    useCreateMessage([queryKeys.CHAT]);

  const { mutate: mutateMaterial, isPending: isLoadingMaterials } =
    useUpdateMaterial(() => {
      openNotification(i18next.t("material.retrieveMessage"));
    });

  const { mutate: rejectSeller, isPending: isRejectingSeller } =
    useRejectSeller(
      () => onOpenNotification(i18next.t("offer.rejectedApplication")),
      () => onOpenNotification(i18next.t("offer.error"))
    );

  const { mutate: validateSeller } = useValidateSeller();

  const { mutate: qualifySample, isPending: isQualifyingSample } =
    useQualifySample((res) => {
      onOpenNotification(
        res.state === SAMPLE_STATE.REJECTED
          ? i18next.t("offer.rejected")
          : i18next.t("offer.approved")
      );
      return setOpen(false);
    });

  const { mutate: requestSample, isPending: isRequestingSample } =
    useRequestSample(
      () => {
        setOpen(false);
        return onOpenNotification(i18next.t("offer.requestedSample"));
      },
      () => onOpenNotification(i18next.t("offer.error"))
    );

  const { mutate: toggleMaterialStatus, isPending: isTogglingMaterialStatus } =
    useToggleMaterialStatus();

  const {
    mutate: createMaterialOfferInvitation,
    isPending: isCreatingInvitation,
  } = useCreateMaterialOfferInvitation(
    () => onOpenNotification(i18next.t("invite.successSupplierInvite")),
    () => onOpenNotification(i18next.t("newMaterialForm.error"))
  );

  const handleTabClick = (index: number) => setSelectedTab(index);

  const handleInviteSupplier = () => setOpenInvite(true);
  const onCloseInvitation = () => setOpenInvite(false);

  const handleDelegate = () => setOpenDelegate(true);
  const onCloseDelegate = () => setOpenDelegate(false);

  const handleRegain = () => {
    mutateMaterial({
      materialId: material?.id,
      body: {
        ...material,
        user,
        owner: { id: user?.id },
      },
    });
  };

  if (
    isLoadingMaterials ||
    isRejectingSeller ||
    isQualifyingSample ||
    isRequestingSample ||
    isCreatingInvitation ||
    isTogglingMaterialStatus
  )
    return <Loader isFullScreen />;

  const pnType = material?.category?.parent?.parent?.parent?.name;

  const isOwner = user?.id === material?.user?.id;
  const isResponsible = user?.id === material?.owner?.id;

  return (
    <Fragment>
      <Actions
        isOwner={isOwner}
        isResponsible={isResponsible}
        material={material}
        goToBidding={goToBidding}
        onClick={handleDelegate}
        onClickRegain={handleRegain}
        onToggleMaterialStatus={toggleMaterialStatus}
      />
      <div className="mx-auto px-4 lg:px-8 pb-8">
        <Tabs
          tabs={
            material?.volume && material?.type !== PN_TYPE.SERVICE
              ? tabs
              : npiTabs
          }
          handleTabClick={handleTabClick}
          selectedTab={selectedTab}
        />
      </div>
      <div className="mx-auto px-4 lg:px-8 xl:grid xl:grid-cols-2">
        {selectedTab === 0 && (
          <Fragment>
            <div className="xl:pr-8 xl:border-r xl:border-gray-200 relative">
              <Detail material={material} pnType={pnType} pn_type={PN_TYPE} />
              {!isLoadingMaterialMarketInfo &&
                material?.type === PN_TYPE.PI && (
                  <div className="shadow border rounded p-2 relative my-1">
                    <PoweredByAI />
                    <FormattedText text={materialMarketInfo?.text} />
                  </div>
                )}
            </div>
            <Suppliers
              materialId={id}
              countryId={
                material?.deliveryPlace?.country?.id ??
                material?.company?.country?.id
              }
              setOpen={setOpen}
              isActive={material?.active}
              onClick={handleInviteSupplier}
              materialOffers={materialOffers}
              setOpenSupplier={setOpenSupplier}
              setOpenChat={setOpenChat}
              countriesByMaterial={countriesByMaterial}
              setMaterialOfferToShow={setMaterialOfferToShow}
              setPotencialSupplierToShow={setPotencialSupplierToShow}
            />
          </Fragment>
        )}
      </div>
      {selectedTab === 1 && material?.type !== PN_TYPE.SERVICE && (
        <PricingVolume
          material={material}
          isOwner={isOwner}
          isResponsible={isResponsible}
        />
      )}
      {selectedTab === 1 && material?.type === PN_TYPE.SERVICE && (
        <BiddingList materialId={material?.id} />
      )}
      {selectedTab === 2 && <BiddingList materialId={material?.id} />}
      <Slide open={open} setOpen={setOpen}>
        {materialOfferToShow && (
          <MaterialOffer
            setOpen={() => setOpen(false)}
            onRejectSeller={rejectSeller}
            onApproveSample={qualifySample}
            onRejectSample={qualifySample}
            onRequestSample={requestSample}
            materialOffer={materialOfferToShow}
            onValidateSeller={validateSeller}
            onChangeToggle={() => setOpen(false)}
          />
        )}
      </Slide>
      <Slide open={openInvite} setOpen={setOpenInvite}>
        <InviteSupplier materialId={id} onCloseInvitation={onCloseInvitation} />
      </Slide>
      <Slide open={openDelegate} setOpen={setOpenDelegate}>
        <Delegate material={material} onCloseDelegate={onCloseDelegate} />
      </Slide>
      <Slide open={openSupplier} setOpen={setOpenSupplier}>
        {potencialSupplierToShow && (
          <PotencialSupplier
            materialId={id}
            isLoadingMaterialOffer={isLoadingMaterialOffer}
            onCreateMaterialOfferInvitation={createMaterialOfferInvitation}
            potencialSupplier={potencialSupplierToShow}
            setOpenSupplier={() => setOpenSupplier(false)}
          />
        )}
      </Slide>
      <Slide open={openChat} setOpen={setOpenChat}>
        <Chat
          chat={chat}
          seller={materialOfferToShow}
          material={material}
          onCreateChat={mutateChat}
          onCreateMessage={mutateMessage}
          isLoadingChat={
            isLoadingChat || isLoadingMessage || isLoadingCreateChat
          }
        />
      </Slide>
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Material);
