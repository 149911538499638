import { Fragment } from "react";
import i18next from "../../../i18n";
import { InfoItem, OrderFileList } from "../../../components";
import { formatDate, labelType } from "../../../utils";
import { ORDER_TYPES } from "../../../types";

const thInverse = [
  { id: 1, name: i18next.t("tenders.tenderSummary.code") },
  { id: 2, name: i18next.t("tenders.tenderSummary.material") },
  { id: 3, name: i18next.t("tenders.tenderSummary.volume") },
  { id: 4, name: i18next.t("tenders.tenderSummary.initialPrice") },
  { id: 5, name: i18next.t("tenders.tenderSummary.percentageDecrement") },
  { id: 6, name: i18next.t("tenders.tenderSummary.tenderPeriod") },
];

const thNormal = [
  { id: 1, name: i18next.t("tenders.tenderSummary.code") },
  { id: 2, name: i18next.t("tenders.tenderSummary.material") },
  { id: 3, name: i18next.t("tenders.tenderSummary.volume") },
  { id: 4, name: i18next.t("tenders.tenderSummary.tenderPeriod") },
];

type MultipleBiddingSummaryProps = {
  orderSummary: any;
  generalBidData: any;
  type: ORDER_TYPES;
  isBid?: boolean;
};

const MultipleBiddingSummary = ({
  type,
  isBid,
  orderSummary,
  generalBidData,
}: MultipleBiddingSummaryProps) => {
  let tableTh = thNormal;

  if (type === ORDER_TYPES.INVERSE_JOINT || type === ORDER_TYPES.INVERSE) {
    tableTh = thInverse;
  }

  return (
    <div className="mt-4">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          {!isBid && (
            <h1 className="text-xl font-semibold text-gray-900 mb-2">
              {i18next.t("tenders.summary")}
            </h1>
          )}
          <InfoItem
            name={i18next.t("tenders.form.tenderType")}
            description={labelType(type)}
          />
          {(type === ORDER_TYPES.JOINT || type === ORDER_TYPES.INVERSE_JOINT) &&
          isBid ? (
            <>
              <InfoItem
                name={i18next.t("tenders.form.internalCode")}
                description={
                  <span className="uppercase font-bold">
                    {orderSummary[0]?.internalCode}
                  </span>
                }
              />
              <InfoItem
                name={i18next.t("tenders.tenderSummary.initialDate")}
                description={formatDate(orderSummary[0]?.startDate)}
              />
              <InfoItem
                name={i18next.t("tenders.tenderSummary.endDate")}
                description={formatDate(orderSummary[0]?.finishDate)}
              />
              <InfoItem
                name={i18next.t("tenders.tenderSummary.paymentMethod")}
                description={orderSummary[0]?.paymentMethod}
              />
              {orderSummary[0]?.comment && (
                <InfoItem
                  name={i18next.t("tenders.tenderSummary.comments")}
                  description={orderSummary[0]?.comment}
                />
              )}
              {orderSummary[0]?.files?.length > 0 && (
                <OrderFileList order={orderSummary[0]} />
              )}
            </>
          ) : (
            <>
              <InfoItem
                name={i18next.t("tenders.form.internalCode")}
                description={
                  <span className="uppercase font-bold">
                    {orderSummary[0]?.internalCode}
                  </span>
                }
              />
              <InfoItem
                name={i18next.t("tenders.tenderSummary.initialDate")}
                description={formatDate(generalBidData?.startDateValue)}
              />
              <InfoItem
                name={i18next.t("tenders.tenderSummary.endDate")}
                description={formatDate(generalBidData?.endDateValue)}
              />
              <InfoItem
                name={i18next.t("tenders.tenderSummary.paymentMethod")}
                description={generalBidData?.paymentMethod}
              />
              <InfoItem
                name={i18next.t("tenders.tenderSummary.paymentTerm")}
                description={`${generalBidData?.paymentTerm} ${
                  generalBidData?.paymentTerm === 1
                    ? ` ${i18next.t("opportunities.table.day")}`
                    : ` ${i18next.t("opportunities.table.days")}`
                }`}
              />
              <InfoItem
                name={i18next.t("tenders.tenderSummary.comments")}
                description={generalBidData?.comment}
              />
              {generalBidData?.files?.length > 0 && (
                <OrderFileList order={generalBidData} />
              )}
            </>
          )}
        </div>
      </div>
      <div className="-mx-4 flex flex-col sm:-mx-6 md:mx-0">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              {tableTh.map((t) => (
                <th
                  key={t.id}
                  scope="col"
                  className="uppercase py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6 md:pl-0"
                >
                  {t.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {orderSummary?.map((order: any) => (
              <tr key={order.id} className="border-b border-gray-200">
                <td className="py-4 text-xs">
                  <div className="font-medium text-gray-900 ">
                    {isBid ? order?.material?.ean : order.ean}
                  </div>
                </td>
                <td className="py-4 text-xs">
                  <div className="font-medium text-gray-900 ">
                    {isBid
                      ? order?.material?.globalMaterialName
                      : order.globalMaterialName}
                  </div>
                </td>
                <td className="py-4 text-xs text-gray-500">
                  {order.quantity} {order.measurementUnit}
                </td>
                {(type === ORDER_TYPES.INVERSE ||
                  type === ORDER_TYPES.INVERSE_JOINT) && (
                  <Fragment>
                    <td className="py-4 text-xs text-gray-500">
                      {order.initialPrice} {order.coin}
                    </td>
                    <td className="py-4 text-xs text-gray-500">
                      {order.minimumDecrement} %
                    </td>
                  </Fragment>
                )}
                <td className="py-4 text-xs text-gray-500">
                  {order.startSupplyDate === order.endSupplyDate ? (
                    formatDate(order.startSupplyDate)
                  ) : (
                    <>
                      {formatDate(order.startSupplyDate)} -{" "}
                      {formatDate(order.endSupplyDate)}
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MultipleBiddingSummary;
