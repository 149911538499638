import { Fragment, useEffect, useReducer, useState } from "react";
import { connect } from "react-redux";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import {
  CheckCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/solid";
import i18next from "../../i18n";
import InfoItem from "../UI/InfoItem/InfoItem";
import Modal from "../UI/Modal/Modal";
import {
  BIDDING_TYPES,
  IT_DOESNT_HAVE_SPEC,
  NO_SPEC,
  TO_BE_AGREED,
  formatDate,
  labelType,
  mapStateToProps,
} from "../../utils";
import JointOfferForm from "../Form/JointOfferForm";
import Slide from "../UI/Slide/Slide";
import { OrderResponse, UserResponse } from "../../types";
import OrderFileList from "../OrderFileList/OrderFileList";

const actions = {
  UPDATE_OFFER_PRICE: "UPDATE_OFFER_PRICE",
  UPDATE_OFFER_COMMENT: "UPDATE_OFFER_COMMENT",
  SET_INITIAL_OFFER: "SET_INITIAL_OFFER",
};

type OrderSummaryProps = {
  user: UserResponse;
  order: OrderResponse;
  type: string;
  currency: string;
  measurementUnit: string;
  onCreateOffer: (request: any) => void;
};

const OrderSummary = ({
  user,
  type,
  currency,
  order,
  measurementUnit,
  onCreateOffer,
}: OrderSummaryProps) => {
  const [open, setOpen] = useState(false);
  const [openSlide, setOpenSlide] = useState(false);
  const [materialToShow, setMaterialToShow] = useState<any>(null);

  const isInverse = type === BIDDING_TYPES.INVERSE;

  const [event, updateEvent] = useReducer(
    (state: any, action: any) => {
      let newState = { ...state };
      switch (action.type) {
        case actions.SET_INITIAL_OFFER:
          newState = {
            ...newState,
            offer: action.payload,
            errors: { ...state.errors },
          };
          break;
        case actions.UPDATE_OFFER_PRICE: {
          if (isInverse) {
            if (
              action.price > newState.offer.initialPrice ||
              action.price < 0
            ) {
              newState.errors[action.id] = `${i18next.t(
                "validations.lowerThanX"
              )} ${newState.offer.initialPrice}`;
            } else {
              delete newState.errors[action.id];
              newState.offer.price = action.price;
            }
          } else {
            newState.offer.price = action.price;
          }
          return newState;
        }
        case actions.UPDATE_OFFER_COMMENT:
          newState.offer.comment = action.comment;
          break;
      }
      return newState;
    },
    {
      offer: order,
      errors: {},
    }
  );

  useEffect(() => {
    const initialState = {
      order: { id: order.id },
      price: 0,
      comment: "",
      user: { id: user?.id },
      initialPrice: order.initialPrice,
      selected: false,
    };
    updateEvent({ type: actions.SET_INITIAL_OFFER, payload: initialState });
  }, [order]);

  let thIsBid = [
    { id: 1, name: i18next.t("tenders.tenderSummary.material") },
    { id: 2, name: i18next.t("tenders.tenderSummary.tenderPeriod") },
    { id: 3, name: i18next.t("tenders.tenderSummary.volume") },
    {
      id: 4,
      name: `${i18next.t(
        "offer.table.price"
      )} - (${currency} / ${measurementUnit}) ${i18next.t(
        "tenders.seller.noTax"
      )}`,
    },
    { id: 5, name: i18next.t("offer.table.total") },
    { id: 6, name: i18next.t("offer.comments") },
  ];

  if (type === BIDDING_TYPES.INVERSE) {
    thIsBid = [
      { id: 1, name: i18next.t("tenders.tenderSummary.material") },
      { id: 2, name: i18next.t("tenders.tenderSummary.tenderPeriod") },
      { id: 3, name: i18next.t("tenders.tenderSummary.initialPrice") },
      { id: 4, name: i18next.t("tenders.tenderSummary.percentageDecrement") },
      { id: 5, name: i18next.t("tenders.tenderSummary.volume") },
      {
        id: 6,
        name: `${i18next.t(
          "offer.table.price"
        )} - (${currency} / ${measurementUnit}) ${i18next.t(
          "tenders.seller.noTax"
        )}`,
      },
      { id: 7, name: i18next.t("offer.comments") },
    ];
  }

  const onSubmit = () => {
    const formattedRequest = {
      ...event.offer,
      price: parseFloat(String(event.offer.price)),
    };

    onCreateOffer(formattedRequest);
  };

  const isButtonDisabled =
    Object.keys(event.errors).length > 0 ||
    event.offer.price === 0 ||
    event.offer.price === null ||
    event.offer.price === undefined ||
    String(event.offer.price) === "";

  return (
    <>
      <Modal
        open={open}
        setOpen={setOpen}
        icon={
          <CheckCircleIcon
            className="h-6 w-6 text-green-600"
            aria-hidden="true"
          />
        }
        title={i18next.t("tenders.seller.offer")}
        message={i18next.t("tenders.seller.question")}
        onClickSuccess={onSubmit}
        onClickCancel={() => setOpen(false)}
      />
      <div className="mt-4">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <InfoItem
              name={i18next.t("tenders.form.company")}
              description={
                <span className="uppercase font-bold text-spectum">
                  {order.company?.name}
                </span>
              }
            />
            <InfoItem
              name={i18next.t("tenders.form.fullName")}
              description={order.user?.fullName}
            />
            <InfoItem
              name={i18next.t("tenders.form.email")}
              description={order.user?.email}
            />
            <InfoItem
              name={i18next.t("tenders.form.phone")}
              description={order.user?.phone}
            />
            <InfoItem
              name={i18next.t("tenders.form.tenderType")}
              description={labelType(order.type)}
            />
            <InfoItem
              name={i18next.t("tenders.tenderSummary.initialDate")}
              description={formatDate(order.startDate)}
            />
            <InfoItem
              name={i18next.t("tenders.tenderSummary.endDate")}
              description={formatDate(order.finishDate)}
            />
            <InfoItem
              name={i18next.t("tenders.tenderSummary.paymentMethod")}
              description={order.paymentMethod}
            />
            <InfoItem
              name={i18next.t("tenders.tenderSummary.paymentTerm")}
              description={
                <>
                  {order.paymentTerm}
                  {order.paymentTerm === 1
                    ? ` ${i18next.t("opportunities.table.day")}`
                    : ` ${i18next.t("opportunities.table.days")}`}
                </>
              }
            />
            {order.comment && (
              <InfoItem
                name={i18next.t("tenders.tenderSummary.comments")}
                description={order.comment}
              />
            )}
            {order.files?.length > 0 && <OrderFileList order={order} />}
            {order.plicationUrl && order.plicationUrl !== NO_SPEC && (
              <div className="bg-white overflow-hidden">
                <div className="py-2 sm:p-0">
                  <dl className="sm:divide-y sm:divide-gray-200">
                    <div className="py-2 sm:grid sm:grid-cols-2 sm:gap-4">
                      <dt className="uppercase text-xxs font-medium text-gray-500">
                        {i18next.t("newMaterialForm.plication")}
                      </dt>
                      <dd className="mt-1 text-xxs text-gray-900 sm:mt-0 sm:col-span-1">
                        <a
                          href={order.plicationUrl}
                          target="_blank"
                          rel="noreferrer"
                          className="inline-flex items-center p-1 border border-transparent shadow-sm text-xxs leading-4 font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum"
                        >
                          <ArrowDownTrayIcon
                            className="-ml-0.5 mr-2 h-4 w-4"
                            aria-hidden="true"
                          />
                          {i18next.t("cta.plication")}
                        </a>
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="-mx-4 flex flex-col sm:-mx-6 md:mx-0">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                {thIsBid.map((t) => (
                  <th
                    key={t.id}
                    scope="col"
                    className="uppercase py-3.5 pl-4 pr-3 text-left text-xxs font-semibold text-gray-900 sm:pl-6 md:pl-0"
                  >
                    {t.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr className="border-b border-gray-200">
                <td className="py-2 text-xxs">
                  <div className="font-medium text-gray-900 flex items-center gap-3">
                    <p className="uppercase font-semibold">
                      {order?.material?.globalMaterialName}
                    </p>
                    <button
                      onClick={() => {
                        setMaterialToShow(order);
                        setOpenSlide(true);
                      }}
                      className="text-xxs uppercase underline"
                    >
                      <InformationCircleIcon className="h-5 w-5 text-spectum" />
                    </button>
                  </div>
                </td>

                <td className="py-2 text-xxs text-gray-500">
                  {order.startSupplyDate === order.endSupplyDate ? (
                    formatDate(order.startSupplyDate)
                  ) : (
                    <>
                      {formatDate(order.startSupplyDate)} -{" "}
                      {formatDate(order.endSupplyDate)}
                    </>
                  )}
                </td>
                {type === BIDDING_TYPES.INVERSE && (
                  <Fragment>
                    <td className="py-2 text-xxs text-gray-900">
                      {order.initialPrice} {order.currency}
                    </td>
                    <td className="py-2 text-xxs text-gray-500">
                      {order.minimumDecrement} %
                    </td>
                  </Fragment>
                )}
                <td className="py-2 text-xxs text-gray-900">
                  {order.quantity} {order.measurementUnit}
                </td>
                <JointOfferForm
                  quantity={order.quantity}
                  errors={event.errors}
                  id={order.id}
                  actions={actions}
                  currency={order.currency}
                  orderType={order.type}
                  initialPrice={order?.initialPrice}
                  updateEvent={updateEvent}
                  measurementUnit={order?.measurementUnit}
                />
              </tr>
            </tbody>
          </table>
          <div className="px-2 py-3 bg-gray-50 text-right">
            <button
              onClick={(e) => {
                e.preventDefault();
                setOpen(true);
              }}
              disabled={isButtonDisabled}
              className={`${
                isButtonDisabled
                  ? "bg-gray-200 text-gray-600 cursor-not-allowed"
                  : "bg-spectum hover:bg-spectum-light text-white"
              } inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-xxs font-medium rounded focus:outline-none focus:ring-2 focus:ring-offset-2 uppercase`}
            >
              {i18next.t("offer.offerNow")}
            </button>
          </div>
        </div>
      </div>
      <Slide open={openSlide} setOpen={setOpenSlide}>
        <div className="p-2 border rounded shadow">
          <p className="uppercase font-bold text-spectum mb-2">
            {materialToShow?.material?.globalMaterialName}
          </p>
          <InfoItem
            isCol
            name={i18next.t("material.incoTerm")}
            description={materialToShow?.material?.incoTerm}
          />

          {materialToShow?.material?.application && (
            <InfoItem
              isCol
              name={i18next.t("material.application")}
              description={materialToShow?.material?.application}
            />
          )}

          <InfoItem
            isCol
            name={i18next.t("material.deliveryFormat")}
            description={materialToShow?.material?.deliveryFormat}
          />

          <InfoItem
            isCol
            name={i18next.t("material.deliveryPlace")}
            description={
              materialToShow?.material?.deliveryPlace
                ? materialToShow?.material?.deliveryPlace?.address
                : TO_BE_AGREED
            }
          />
          <div className="bg-white overflow-hidden">
            <div className="py-2 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-2 flex flex-col gap-2">
                  <dt className="uppercase text-xxs font-medium text-gray-500">
                    {i18next.t("material.specification")}
                  </dt>
                  <dd className="mt-1 text-xxs text-gray-900 sm:mt-0 sm:col-span-1">
                    {materialToShow?.material?.fileURL === NO_SPEC ? (
                      <p>{IT_DOESNT_HAVE_SPEC}</p>
                    ) : (
                      <a
                        href={materialToShow?.material?.fileURL}
                        target="_blank"
                        rel="noreferrer"
                        className="inline-flex items-center p-1 border border-transparent shadow-sm text-xxs leading-4 font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum"
                      >
                        <ArrowDownTrayIcon
                          className="-ml-0.5 mr-2 h-4 w-4"
                          aria-hidden="true"
                        />
                        {i18next.t("cta.download")}
                      </a>
                    )}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          {materialToShow?.material?.comments && (
            <InfoItem
              isCol
              name={i18next.t("material.comments")}
              description={materialToShow?.material?.comments}
            />
          )}
        </div>
      </Slide>
    </>
  );
};

export default connect(mapStateToProps)(OrderSummary);
